<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class RefundRequestCardSection extends Vue {

    @Prop({ type: Boolean, default: false })
        separator!: boolean;

    @Prop({ type: Boolean, default: false })
        accent!: boolean;

    @Prop({ type: Boolean, default: false })
        highlightError!: boolean;

    @Prop({ type: Boolean, default: false })
        disabled!: boolean;

}
</script>
