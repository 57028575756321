<template>
    <div class="order-item-metadata-form">
        <Collapse>
            <div v-show="!noFriendsDetails">
                <form @submit.prevent="$emit('save')">
                    <div class="order-item-metadata-form__content">
                        <OrderTicketMetadataFormItem
                            :metadata="ticket.metadata"
                        />
                        <div
                            v-for="(product, i) in productsWithMetadata"
                            :key="product.guid"
                        >
                            <div
                                v-if="
                                    product.metadata && product.metadata.length
                                "
                                class="order-item-metadata-form__group"
                            >
                                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                                <label
                                    class="
                                        ot-text-tiny-strong
                                        order-item-metadata-form__group__title
                                    "
                                >
                                    {{ product.product.name }} ({{ i + 1 }}/{{
                                        productsWithMetadata.length
                                    }})
                                </label>
                                <OrderTicketMetadataFormItem
                                    :metadata="product.metadata"
                                />
                            </div>
                        </div>
                        <div class="order-item-metadata-form__action">
                            <button
                                class="ot-button is-fullwidth"
                                :class="{
                                    'is-loading': loading,
                                }"
                                type="button"
                                @click="save"
                            >
                                <i
                                    class="ot-button-icon--left oti oti-check"
                                />
                                {{
                                    $t(
                                        'order.components.order_ticket_metadata_form.save_button'
                                    )
                                }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Collapse>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { IOrderProduct, IOrderTicket } from '@openticket/lib-order';
import OrderTicketMetadataFormItem from './OrderTicketMetadataFormItem.vue';

@Component({
    components: { OrderTicketMetadataFormItem },
})
export default class OrderTicketMetadataForm extends Vue {

    @Prop({ required: true })
    private ticket!: IOrderTicket;

    @Prop()
    private loading!: boolean;

    // Invite functionality
    private noFriendsDetails = false;

    private get productsWithMetadata(): IOrderProduct[] {
        return this.ticket.products.filter(
            (product: IOrderProduct) => product.metadata && product.metadata.length,
        );
    }

    private save() {
        this.$emit('save');
    }

}
</script>

<style lang="scss" scoped>
.order-item-metadata-form {
    &__content {
        padding-top: 0;
    }

    &__group {
        border-top: 2px solid Var(--ot-shop-color-box-accent);
        padding-top: 1.25rem;

        &__title {
            margin-left: 1.25rem;
            margin-bottom: 0.75rem;
            opacity: 0.5;
            display: block;
        }
    }

    &__action {
        margin: 0.5rem 1.25rem 0;
        padding-bottom: 1.25rem;
    }
}
</style>
