<template>
    <div
        v-if="baseInitialized"
        class="error-view"
    >
        <BrandedHeader />

        <div class="ot-card error-view__card">
            <div class="error-view__card__icon">
                <div class="oti oti-alert is-large" />
            </div>

            <h2 class="error-view__card__title">
                {{ $t(shownTitle) }}
            </h2>

            <p class="error-view__card__description">
                {{ $t(shownDescription) }}
            </p>

            <p class="error-view__card__session">
                Session ID: {{ $log.session }}
            </p>

            <a
                v-if="redirectUrl"
                :href="redirectUrl"
            >
                <button
                    class="
                        ot-button
                        is-fullwidth is-large
                        error-view__card__button
                    "
                >
                    {{ $t('shop.components.shop_error.retry_button') }}
                    <i class="ot-button-icon--right oti oti-arrow-right" />
                </button>
            </a>
        </div>

        <Footer hide-cookie-link />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';

@Component
export default class ShopError extends Vue {

    @InjectReactive('baseInitialized')
        baseInitialized!: boolean;

    @Prop()
        title!: string;

    @Prop()
        description!: string;

    created(): void {
        const { sessionId } = this.$route.query;

        if (!sessionId) {
            void this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    sessionId: this.$log.session,
                },
            });
        }
    }

    get type(): string {
        return 'Order';
    }

    get shownTitle(): string {
        if (this.$route.query.title) {
            const translated = this.$t(this.$route.query.title as string);
            if (translated !== this.$route.query.title) {
                return translated;
            }
        }

        if (this.title) {
            return this.$t(this.title);
        }

        return this.$t('shop.components.shop_error.title');
    }

    get shownDescription(): string {
        if (this.$route.query.description) {
            const translated = this.$t(this.$route.query.description as string);
            if (translated !== this.$route.query.description) {
                return translated;
            }
        }

        if (this.description) {
            return this.$t(this.description);
        }

        return this.$t('shop.components.shop_error.description');
    }

    get redirectUrl(): string {
        const url = this.$route.query.redirect as string;
        if (url) {
            const { resolved } = this.$router.resolve({
                path: url,
                query: {
                    sessionId: this.$log.session,
                },
            });
            if (resolved.name !== 'error') {
                return resolved.fullPath;
            }
        }

        return '';
    }

}
</script>

<style lang="scss" scoped>
.error-view {
    &__card {
        text-align: center;
        padding: var(--ot-spacing-2xl);

        &__icon {
            margin-bottom: var(--ot-spacing-2xl);
        }

        &__title {
        }

        &__description {
            opacity: 0.5;
        }

        &__button {
            margin-top: 2rem;
        }
    }
}
</style>
