<template>
    <CardSection
        class="refund-request-select"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <h3 class="refund-request-select__title">
            {{ $t('order.components.refund_request_card.select.title') }}
        </h3>

        <ot-input-checkbox
            v-model="selectEntireEvent"
            class="refund-request-select__entire-event"
            :label="
                $t('order.components.refund_request_card.select.entire_event')
            "
            :disabled="disabled"
            :class="{ disabled }"
            @input="entireEventChanged"
        />

        <div
            class="refund-request-select__items refund-request-select__tickets"
        >
            <RefundRequestCheckbox
                v-for="ticket in tickets"
                :key="ticket.guid"
                class="
                    refund-request-select__item refund-request-select__ticket
                "
                :guid="ticket.guid"
                :selected="!!ticketSelection[ticket.guid]"
                :disabled="disabled"
                :class="[
                    `refund-request-select__order-ticket__guid__${ticket.guid}`,
                    `refund-request-select__order-ticket__ticket-number__${ticket.ticket_number}`,
                    `refund-request-select__ticket__guid__${ticket.ticket.guid}`,
                ]"
                @input="selectTicket(ticket, $event)"
            >
                <div class="refund-request-select__item__label">
                    <h6>{{ ticket.ticket.name }}</h6>
                    <div class="refund-request-select__item__label__sublabel">
                        <span>{{ ticket.ticket_number }}</span>
                        <span
                            :key="
                                `refund-request-select__item__label__sublabel`+
                                    `.ticket.${ticket.guid}.finn_price.${ticket.finn_price}`
                            "
                        >{{
                            $l.currency(
                                !refundSettings ||
                                    refundSettings.refund_fees
                                    ? ticket.finn_price
                                    : ticket.finn_price -
                                        ticket.finn_service_fee,
                                $order.data.shop.currency
                            )
                        }}</span>
                    </div>
                </div>
            </RefundRequestCheckbox>
        </div>

        <!-- <div
            class="refund-request-select__items refund-request-select__products"
        > -->
        <!-- Currently data does not properly capture shop-products / shoptionals, so can't add these (yet) -->
        <!-- </div> -->
    </CardSection>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import type { IOrderRefundSetting, IOrderTicket } from '@openticket/lib-order';
import CardSection from '../CardSection.vue';
import RefundRequestCardSection from './RefundRequestCardSection.vue';
import RefundRequestCheckbox from './RefundRequestCheckbox.vue';
import type { RefundItemCollection } from './types';

@Component({ components: { RefundRequestCheckbox, CardSection } })
export default class RefundRequestSelect extends RefundRequestCardSection {

    selectEntireEvent = false;

    @Prop({ required: true })
        refundSettings!: IOrderRefundSetting;

    @Prop({ required: true, type: Array })
        tickets!: IOrderTicket[];

    @PropSync('selectedTickets', { required: true, type: Object })
        ticketSelection!: RefundItemCollection;

    get allTicketsSelected(): boolean {
        for (const ticket of this.tickets) {
            if (!this.ticketSelection[ticket.guid]) {
                return false;
            }
        }

        return true;
    }

    selectTicket(ticket: IOrderTicket, value = true): void {
        if (value) {
            this.$set(this.ticketSelection, ticket.guid, ticket);

            this.selectEntireEvent = this.allTicketsSelected;
        } else {
            this.$delete(this.ticketSelection, ticket.guid);

            this.selectEntireEvent = false;
        }
    }

    entireEventChanged(val: boolean): void {
        for (const ticket of this.tickets) {
            if (val) {
                this.$set(this.ticketSelection, ticket.guid, ticket);
            } else {
                this.$delete(this.ticketSelection, ticket.guid);
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.refund-request-select {
    &__entire-event {
        display: inline-flex;
        width: unset;

        margin: 1rem 0 0 0;
    }

    &__items {
        margin: 2.5rem 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item__label {
        display: flex;
        flex-direction: column;

        &__sublabel {
            display: flex;
            justify-content: space-between;
        }
    }

    &::v-deep .ot-checkbox {
        & > input[type='checkbox'] + label.ot-checkbox-label {
            padding: 1rem 1.25rem 1rem 3.25rem;
            text-align: initial;

            &::before,
            &::after {
                top: 50%;
                left: 1.25rem;
                margin-top: -0.625rem;
            }
        }

        &[disabled],
        &.disabled {
            pointer-events: none;
        }

        &[disabled] > input[type='checkbox'],
        &.disabled > input[type='checkbox'],
        & > input[type='checkbox'][disabled],
        & > input[type='checkbox'].disabled {
            & + label.ot-checkbox-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
