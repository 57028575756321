<template>
    <CardSection
        class="refund-request-payout"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <h3>{{ $t('order.components.refund_request_card.payout.title') }}</h3>

        <div class="refund-request-payout__items">
            <RefundRequestRadio
                v-for="payoutType in payoutTypes"
                :key="payoutType.type"
                class="refund-request-payout__item"
                :guid="payoutType.type"
                :selected="payoutTypeSelected === payoutType.type"
                :class="`refund-request-payout__item__${payoutType.type}`"
                :disabled="disabled"
                @input="selectType(payoutType.type, $event)"
            >
                <div class="refund-request-payout__item__label">
                    <h6>{{ $t(payoutType.slug) }}</h6>
                    <span
                        v-if="payoutAmount !== null"
                        :key="
                            `refund-request-payout__item__label.${payoutType.type}.payoutAmount.${payoutAmount}`
                        "
                    >{{
                        $l.currency(payoutAmount, $order.data.shop.currency)
                    }}</span>
                </div>
            </RefundRequestRadio>
        </div>
    </CardSection>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import type { IOrderRefundSetting } from '@openticket/lib-order';
import CardSection from '../CardSection.vue';
import RefundRequestCardSection from './RefundRequestCardSection.vue';
import RefundRequestRadio from './RefundRequestRadio.vue';
import type { PayoutSpec, PayoutType } from './types';

@Component({ components: { CardSection, RefundRequestRadio } })
export default class RefundRequestPayout extends RefundRequestCardSection {

    @PropSync('payoutType', { required: true, default: null })
        payoutTypeSelected!: null | PayoutType;

    @Prop({ required: true })
        refundSetting!: IOrderRefundSetting;

    @Prop({ required: true, type: Number })
        payoutAmount!: null | number;

    get payoutTypes(): PayoutSpec[] {
        if (!this.refundSetting) {
            return [];
        }

        const payoutTypes: PayoutSpec[] = [];

        if (this.refundSetting.refundable_by_voucher) {
            payoutTypes.push({
                type: 'voucher',
                slug: 'order.components.refund_request_card.payout.voucher',
            });
        }

        if (this.refundSetting.refundable_by_payment) {
            payoutTypes.push({
                type: 'cash',
                slug: 'order.components.refund_request_card.payout.cash',
            });
        }

        if (payoutTypes.length === 1) {
            this.payoutTypeSelected = payoutTypes[0].type;
        } else {
            this.payoutTypeSelected = null;
        }

        return payoutTypes;
    }

    selectType(type: PayoutType, val: boolean): void {
        if (val) {
            this.payoutTypeSelected = type;
        } else if (this.payoutTypeSelected === type) {
            this.payoutTypeSelected = null;
        }
    }

}
</script>

<style lang="scss" scoped>
.refund-request-payout {
    &__items {
        margin: 2.5rem 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item__label {
        display: flex;
        flex-direction: column;
    }

    &::v-deep .ot-radio {
        &[disabled],
        &.disabled {
            pointer-events: none;
        }

        &[disabled] > input[type='radio'],
        &.disabled > input[type='radio'],
        & > input[type='radio'][disabled],
        & > input[type='radio'].disabled {
            & + label.ot-radio-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
