import Style from '@openticket/lib-style';
import { ModalProgrammatic } from 'buefy';
import type Vue from 'vue';

export default function openModal(options: {
    component: typeof Vue,
    props: unknown,
    parent?: Vue,
    events: {
        close?: () => void,
        save: () => void,
    },
}): void {
    const width = new Style({}).get('layout-width');

    const el = ModalProgrammatic.open(
        {
            width,
            animation: 'fade',
            scroll: 'clip',
            canCancel: false,
            ...options,

            events: {
                close: () => {
                    el.close();
                },
                ...options.events,
            },
        },
    );
}
