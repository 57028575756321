<template>
    <div class="back-to-shop">
        <a :href="url"><button class="ot-button is-large">
            <i class="ot-button-icon--left oti oti-check" />
            {{ $t('order.components.back_to_shop.button') }}
        </button></a>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import urlJoin from 'url-join';

@Component
export default class BackToShop extends Vue {

    private get url(): string {
        return urlJoin(this.$whitelabel.shop.url, this.$order.data.shop_id);
    }

}
</script>

<style lang="scss" scoped>
.back-to-shop {
    text-align: center;
    margin-bottom: 1rem;
}
</style>
