<template>
    <div
        v-if="show"
        class="ot-card resell"
    >
        <h4 class="resell__title">
            {{ $t('order.components.resell.title') }}
        </h4>
        <div class="ot-text-body resell__subtitle">
            {{ $t('order.components.resell.subtitle') }}
        </div>
        <a
            v-if="showTicketSwap"
            target="_blank"
            class="resell__link ticketswap"
            :href="ticketSwapUrl"
        >
            <button class="ot-button is-fullwidth">
                {{ $t('order.components.resell.button') }}
                <img
                    class="ot-button-icon--right"
                    height="20px"
                    src="../../../assets/images/brands/ticketswap_white.png"
                    alt="Ticketswap"
                >
            </button>
        </a>
        <a
            v-if="showTixel"
            target="_blank"
            class="resell__link tixel"
            :href="tixelUrl"
        >
            <button class="ot-button is-fullwidth">
                {{ $t('order.components.resell.button') }}
                <img
                    class="ot-button-icon--right"
                    height="20px"
                    src="../../../assets/images/brands/tixel_white.png"
                    alt="Tixel"
                >
            </button>
        </a>
        <div class="resell__footnote ot-label">
            {{ $t('order.components.resell.footer') }}
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Resell extends Vue {

    show = false;

    created(): void {
        this.show = this.shouldShow();
    }

    shouldShow(): boolean {
        for (const ticket of Object.values(this.$order.data.ticket_map)) {
            if (ticket.retrievable_after) {
                return this.showTicketSwap || this.showTixel;
            }
        }
        return false;
    }

    get showTicketSwap(): boolean {
        return (
            !!this.$whitelabel.order.ticketswap_resell_url
            && !!this.$settings?.static.order.enableTicketSwap
        );
    }

    get ticketSwapUrl(): string {
        return (
            this.$whitelabel.order.ticketswap_resell_url + this.$order.data.guid
        );
    }

    get showTixel(): boolean {
        return (
            !!this.$whitelabel.order.tixel_resell_url
            && !!this.$settings?.static.order.enableTixel
        );
    }

    get tixelUrl(): string {
        return this.$whitelabel.order.tixel_resell_url + this.$order.data.guid;
    }

}
</script>

<style lang="scss" scoped>
.resell {
    text-align: center;
    margin-bottom: 1rem;

    &__title {
        margin-top: 0.5rem;
    }

    &__subtitle {
        margin-bottom: 1.5rem;
    }

    &__footnote {
        margin-top: 1rem;
    }

    &__link button {
        margin-bottom: 1rem;
    }

    &__link.tixel img {
        margin-top: -0.3rem;
    }
}
</style>
