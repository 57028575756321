<template>
    <div class="order-product">
        <OrderItem
            :title="product.product.name"
            :opened="opened"
            :locked="locked"
            :invalidated-reason="product.invalidated_reason"
            :invalidated-since="product.invalidated_since"
            :is-cancelled="isCancelled"
            :is-invalid="isInvalid"
            :is-resold="isResold"
            :has-content="false"
            @toggle="$emit('toggle')"
        >
            <div>
                <div
                    v-if="product.product.description"
                    class="order-product__description ot-text-small"
                >
                    {{ product.product.description }}
                </div>

                <OrderItemMetadata :metadata="product.metadata" />
            </div>
        </OrderItem>
    </div>
</template>

<script lang="ts">
import type { IOrderProduct } from '@openticket/lib-order';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import OrderItem from './OrderItem/OrderItem.vue';
import OrderItemMetadata from './OrderItem/OrderItemMetadata.vue';

@Component({
    components: {
        OrderItem,
        OrderItemMetadata,
    },
})
export default class OrderProduct extends Vue {

    @Prop() product!: IOrderProduct;
    @Prop() opened!: boolean;
    @Prop() locked!: boolean;

    public get refundInProgress(): boolean {
        return (
            !!this.product.invalidated_since
            && (this.product.invalidated_reason === 'return by visitor'
                || this.product.invalidated_reason === 'returned')
        );
    }

    public get isInvalid(): boolean {
        return !!this.product.invalidated_reason && !this.refundInProgress;
    }

    public get isResold(): boolean {
        return (
            !!this.product.invalidated_since
            && this.product.invalidated_reason === 'ticket is ticketswapped'
        );
    }

    get isCancelled(): boolean {
        return (
            this.product.invalidated_reason === 'ticket-cancelled'
            || this.product.invalidated_reason === 'product-cancelled'
        );
    }

}
</script>

<style lang="scss" scoped>
.order-product {
    &__description {
        margin: 0 1.25rem 1.25rem;
    }
}
</style>
