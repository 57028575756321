import Vue from 'vue';
import { OrderClient } from '@openticket/lib-order';
import { Log, registerGlobalErrorHandlers } from '@openticket/lib-log';
import { meta } from '@openticket/lib-release-meta';
import { i18n } from '../../base';
import Base from '../../Base.vue';
import { validateMetadata } from '../../utils/sdk/metadata';
import router from './router';
import type { CookieManager } from '../../composables/cookies';

declare module 'vue/types/vue' {
    interface Vue {
        $cookies: CookieManager;
        $order: OrderClient;
        $log: Log;
    }
}

declare global {
    interface Window {
        Order: OrderClient;
        IsAppleDevice: boolean | null;
    }
}

const sessionId = new URLSearchParams(window.location.search).get('sessionId') || undefined;

const log = Log.register({
    appName: meta.appName,
    appVersion: meta.appVersion,
    url: import.meta.env.VITE_LOGGER_URL || 'https://notify.openticket.tech/',
});

Object.defineProperty(Vue.prototype, '$log', {
    get: () => log,
});

registerGlobalErrorHandlers();

if (sessionId) {
    log.linkSession(sessionId);
}

// Add order SDK to vue prototype
const orderClient = Vue.observable(new OrderClient({
    sessionId: log.session,
    validatorFn: validateMetadata,
}));
Object.defineProperty(Vue.prototype, '$order', {
    get: () => orderClient,
});
Object.defineProperty(window, 'Order', {
    get: () => orderClient,
});
Vue.mixin({
    provide: {
        order: orderClient,
    },
});

new Vue({
    router,
    i18n,
    render: (h) => h(Base),
}).$mount('#app');
