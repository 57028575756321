function scrollToSmoothly(pos: number | string, time: number) {
    /* Time is exact amount of time the scrolling will take (in milliseconds) */
    /* Pos is the y-position to scroll to (in pixels) */
    /* Code written by hev1 */
    if (typeof pos !== 'number') {
        pos = parseFloat(pos);
    }
    if (Number.isNaN(pos)) {
        throw Error('Position must be a number');
    }
    if (pos < 0 || time < 0) {
        return;
    }
    const currentPos = window.scrollY || window.screenTop;
    let start: number | null = null;
    time = time || 500;
    window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        if (currentPos < pos) {
            const progress = currentTime - start;
            window.scrollTo(
                0,
                ((pos - currentPos) * progress) / time + currentPos,
            );
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        } else {
            const progress = currentTime - start;
            window.scrollTo(
                0,
                currentPos - ((currentPos - pos) * progress) / time,
            );
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        }
    });
}

export function scrollToOffset(offset: number): void {
    scrollToSmoothly(offset, 300);
}

export interface ScrollToConfig {
    id?: string;
    element?: HTMLElement;
    delay?: number;
    position?: ScrollLogicalPosition;
}

export default function scrollTo(config: ScrollToConfig): void {
    let elem!: HTMLElement;

    if (config.element) {
        elem = config.element;
    } else {
        elem = document.getElementById(config.id as string) as HTMLElement;
    }

    if (!elem) {
        return;
    }

    setTimeout(() => {
        elem.scrollIntoView({
            behavior: 'smooth',
            block: config.position,
        });
    }, config.delay || 0);
}
