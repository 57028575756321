<template>
    <div
        id="app"
        class="order-view"
    >
        <div v-if="initialized">
            <router-view />

            <OrderFooter @openCookieWall="triggerCookieWallOpen++" />

            <CookieWall :trigger-cookie-wall-open="triggerCookieWallOpen" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Inject, InjectReactive } from 'vue-property-decorator';
import { type LoadOptions, RudderAnalytics } from '@rudderstack/analytics-js';
import { type IOrderConfig, StringMessage } from '@openticket/lib-order';
import { Log, send } from '@openticket/lib-log';
import { setValidationLang } from '../../utils/sdk/metadata';
import VueError from '../../error';

import OrderFooter from './components/OrderFooter.vue';
import type { BaseInit, OverlayManager } from '../../types';
import CookieWall from '../../components/cookie/CookieWall.vue';
import type { CookiePreferences } from '../../composables/cookies';
import { getCookieMessages, initCookies } from './cookies';

@Component({ components: { CookieWall, OrderFooter } })
export default class OrderView extends Vue {

    config!: IOrderConfig;

    @Inject('overlay')
        overlay!: OverlayManager;

    @Inject('baseInit')
        baseInit!: BaseInit;

    @InjectReactive('localizationInitPromise')
        localizationInitPromise!: Promise<void>;

    rudderAnalytics = new RudderAnalytics();

    initialized = false;

    triggerCookieWallOpen = 0;

    async created(): Promise<void> {
        this.$localization.on('locale-change', (locale: string) => {
            setValidationLang(locale);
        });

        await Promise.all([ this.initCookies(), this.initOrder() ]);
    }

    async initOrder(): Promise<void> {
        const closeOverlay: () => void = this.overlay.show();

        if (!import.meta.env.VITE_SHOP_API_URL) {
            throw new VueError('Shop API url is not set.', new Error(), 'osp.errors.missing_shop_url');
        }

        try {
            this.config = {
                baseUrl: import.meta.env.VITE_SHOP_API_URL,
                orderGuid: this.$route.params.order_id,
            };

            if (import.meta.env.VITE_LOGGER_URL) {
                this.config.loggingUrl = import.meta.env.VITE_LOGGER_URL;
                this.config.logLevel = Log.Debug;
            }

            await this.$order.init(this.config);

            Vue.observable(this.$order.data);

            const { shop } = this.$order.data;

            await this.baseInit(shop.guid);

            setValidationLang(this.$localization.locale.locale);

            if (this.$whitelabel.shop.gtm_code) {
                this.$order.tracking.addGTM(this.$whitelabel.shop.gtm_code);
            }

            if (
                this.$whitelabel.shop.rudderstack_data_plane_url
                && this.$whitelabel.shop.rudderstack_write_key
            ) {
                const rudderstackLoadOptions: Partial<LoadOptions> = {
                    sendAdblockPage: true,
                    // These settings should be amended when all projects are using 3.x
                    // https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/migration-guide/#migration-considerations
                    storage: {
                        encryption: {
                            version: 'legacy',
                        },
                        migrate: false,
                    },
                    plugins: [
                        'GoogleLinker',
                        'NativeDestinationQueue',
                        'StorageEncryptionLegacy',
                        'StorageEncryption',
                        'StorageMigrator',
                        'DeviceModeDestinations',
                    ],
                };

                if (this.$whitelabel.shop.rudderstack_cdn_url) {
                    rudderstackLoadOptions.pluginsSDKBaseURL = this.$whitelabel.shop.rudderstack_cdn_url;
                }
                if (this.$whitelabel.shop.rudderstack_config_url) {
                    rudderstackLoadOptions.configUrl = this.$whitelabel.shop.rudderstack_config_url;
                }

                this.rudderAnalytics.load(
                    this.$whitelabel.shop.rudderstack_write_key,
                    this.$whitelabel.shop.rudderstack_data_plane_url,
                    rudderstackLoadOptions,
                );

                // Make sure to remove anonymous_id from url when present
                this.rudderAnalytics.ready(() => {
                    void this.$router.replace({ query: { ajs_aid: undefined } });
                });

                // Make sure to reset all traits and start clean (only leave anonymousId intact)
                this.rudderAnalytics.reset();

                // Set new trait with current shop_id
                this.rudderAnalytics.page(
                    'order',
                    'order',
                    {},
                    {
                        context: {
                            traits: {
                                order_id: this.$order.data.guid,
                                shop_id: this.$order.data.shop_id,
                            },
                        },
                    },
                );
            }

            const safePreferences: Partial<CookiePreferences> = this.$cookies.getSafePreferences();
            if (
                shop.google_tag
                && (this.$cookies.state === 'complete')
                && safePreferences
                && safePreferences.organiserMarketing
            ) {
                // At this point, we can not rely on cookies triggering a consent update to add the shop's GTM container.
                // If the cookies are accepted later, this is OK, the accept listener will try to add it again.
                this.$order.tracking.addGTM(shop.google_tag);
            }

            try {
                if (this.$route.params.status === 'paid') {
                    this.$order.sendLegacyEvents();
                } else {
                    this.$order.sendLegacyEventsWithoutConversion();
                }
            } catch (e) {
                // No-op - Tracking failures should not break flow!
                send(
                    new StringMessage(
                        'osp.order.initOrder.send_legacy_events_failed',
                        '[initOrder] Sending events failed',
                        { error: e },
                    ),
                    Log.Error,
                );
            }

            // Set document title
            if (shop.name) {
                window.document.title = shop.name;
            }

            window.IsAppleDevice = this._isAppleDevice();

            if (this.$route.query.sessionId) {
                void this.$router.replace({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        sessionId: undefined,
                    },
                });
            }
        } catch (e) {
            if (!e || typeof e !== 'object' || !('isOpenTicketError' in e) || !e.isOpenTicketError) {
                if (e instanceof Error) {
                    send(
                        new VueError('initOrder:', e, 'osp.init_order.failed'),
                        Log.Info,
                    );
                }
            }

            void this.$router.push({
                name: 'error',
                query: {
                    redirect: this.$route.path,
                },
            });
        } finally {
            this.initialized = true;

            closeOverlay();
        }
    }

    async initCookies(): Promise<void> {
        await this.localizationInitPromise;

        this.$cookies.onChange(
            (newPreferences: Partial<CookiePreferences>) => {
                this.cookiePreferencesUpdated(
                    newPreferences,
                    this.$cookies.getComparableState(),
                );
            },
        );

        await initCookies(
            this.$cookies,
            getCookieMessages(this),
        );
    }

    cookiePreferencesUpdated(preferences: Partial<CookiePreferences>, comparableState: string): void {
        try {
            send(
                new StringMessage(
                    'osp.order.cookie.preferences_update.info',
                    'Debug message for the order cookies',
                    {
                        path: [ 'order', 'cookies', 'debug', 'message' ],
                        preferences,
                        comparableState,
                    },
                ),
                Log.Info,
            );
        } catch (e) {
            console.error('Failed to log cookies debug message', e, comparableState);
        }

        try {
            // Cookie preferences can be updated when:
            // - Saved preferences are retrieved throught the cookie client;
            // - The user has manually updated (a subset of) cookie preferences;

            // The preferences can be individually present in the preferences object.
            // Personalised ads will only be accepted when both the base ads and
            // personalized ads properties are set to true.
            if (!preferences) {
                this.$order.tracking.revokeConsent();

                return;
            }

            if (
                this.$order.data
                && this.$order.data.shop
                && typeof this.$order.data.shop === 'object'
                && 'google_tag' in this.$order.data.shop
            ) {
                // When organiserMarketing is accepted, the order is initialized and its shop has a GTM code,
                // the shop specific GTM container is loaded.
                //
                // DD-SHOP-2714
                //
                // The addGTM method can be triggered as often as convenient.
                // The method will silently ignore GTM codes which were already succesfully registered.
                const { google_tag } = this.$order.data.shop;

                if (
                    preferences.organiserMarketing
                    && this.$order.initialized
                    && google_tag
                ) {
                    // Ignored when the order has not finished loading
                    // This is OK -> After the order is initialized, it is tried as well.
                    this.$order.tracking.addGTM(google_tag);
                }
            }

            this.$order.tracking.updateConsent(preferences);
        } catch (e) {
            send(
                new StringMessage(
                    'error.osp.order.cookie.preferences_update.failed',
                    'Failed to update cookie preferences',
                    {
                        path: [ 'order', 'cookies', 'failed', 'update' ],
                        preferences,
                        comparableState,
                        error: e,
                    },
                ),
                Log.Error,
            );
        }
    }

    _isAppleDevice(): boolean {
        try {
            return (
                [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod',
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes('Mac')
                    && 'ontouchend' in document)
            );
        } catch {
            return false;
        }
    }

}
</script>

<style lang="scss" scoped>
.order-view {
    text-align: center;

    &__retry-polling {
        margin-bottom: 1rem;
    }

    &__download-all {
        margin-bottom: 1rem;
    }

    &__footer {
        &__text {
            max-width: 24rem;
            margin: 1rem auto;
        }
    }
}
</style>
