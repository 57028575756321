<template>
    <div class="order-ticket-actions__wallet">
        <div class="order-ticket-actions__wallet__text">
            <div class="ot-text-tiny">
                {{
                    $t(
                        'order.components.order_ticket_actions.apple_wallet.description'
                    )
                }}
            </div>
        </div>
        <a
            class="order-ticket-actions__wallet__badge"
            :class="{ 'overlay ot-spinner': loading, 'error-overlay': error }"
            :title="
                $t(
                    'order.components.order_ticket_actions.apple_wallet.button.label'
                )
            "
            target="_blank"
            href="#"
            @click.prevent="requestPkPassFile(ticket.guid)"
        >
            <img
                src="../../../../../assets/images/icons/apple_wallet.svg"
                :alt="$t(
                    'order.components.order_ticket_actions.apple_wallet.button.label'
                )"
            >
            <div
                v-if="error"
                class="error"
            ><i class="oti oti-alert" /></div>
        </a>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { IOrderTicket } from '@openticket/lib-order';
import axios from 'axios';
import urlJoin from 'url-join';

const MAX_PKPASSFILE_ATTEMPTS = 5;
const PKPASSFILE_POLL_TIMEOUT_MS = 1000;

@Component
export default class AppleWallet extends Vue {

    loading = false;
    error = false;

    pkPassUrl: string | null = null;
    pkPassPollingAttempts = 0;

    @Prop() ticket!: IOrderTicket;

    async requestPkPassFile(ticketId: string): Promise<void> {
        if (this.loading) {
            return;
        }

        this.error = false;
        this.loading = true;
        const fetchUrl = urlJoin([
            import.meta.env.VITE_WALLET_URL,
            '/apple',
            this.$order.data.guid,
            ticketId,
        ]);

        try {
            await axios.get(fetchUrl);
            // Hold your horses, the catch-state is actually the state we want to have here.
            // The backend is returning a 355 status code with an X-Location header.
            // We can ignore all other 2XX status codes.
            this.loading = false;
            this.error = true;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const { response } = e;
                if (
                    response
                    && response.status === 355
                    && response.headers['x-location'] !== ''
                    && typeof response.headers['x-location'] === 'string'
                ) {
                    this.pkPassUrl = response.headers['x-location'];
                    setTimeout(
                        () => {
                            void this._pollPkPassAvailable();
                        },
                        PKPASSFILE_POLL_TIMEOUT_MS,
                    );
                } else {
                    this.error = true;
                    this.loading = false;
                }
            } else {
                throw e;
            }
        }
    }

    // Perform HEAD requests to see if the file is accessible for the user
    async _pollPkPassAvailable(): Promise<void> {
        this.pkPassPollingAttempts++;

        if (
            this.pkPassUrl === null
            || this.pkPassPollingAttempts > MAX_PKPASSFILE_ATTEMPTS
        ) {
            this.loading = false;
            this.error = true;
            this.pkPassPollingAttempts = 0;
            return Promise.resolve();
        }

        try {
            await axios.head(this.pkPassUrl);
            window.location.href = this.pkPassUrl;

            this.loading = false;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setTimeout(
                    () => {
                        void this._pollPkPassAvailable();
                    },
                    PKPASSFILE_POLL_TIMEOUT_MS,
                );
            } else {
                throw e;
            }
        }
        return Promise.resolve();
    }

}
</script>

<style lang="scss" scoped>
.order-ticket-actions {
    &__wallet {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        margin-bottom: var(--ot-spacing-default);

        &__text {
            @media (max-width: 40em) {
                display: none;
            }
        }

        &__badge {
            position: relative;
            flex-grow: 1;
            display: flex;
            justify-content: center;

            img {
                width: 10em;
            }

            .error {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &.overlay {
                img {
                    visibility: hidden;
                }
            }

            &.error-overlay {
                img {
                    filter: grayscale(1) opacity(0.3);
                }
            }
        }
    }
}
</style>
