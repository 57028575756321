<template>
    <div class="ot-card wallets-message">
        <h2 class="wallets-message__title">
            {{ $t('order.components.wallets_message.title') }}
        </h2>
        <div class="wallets-message__content">
            {{ $t('order.components.wallets_message.subtitle') }}
            <a
                v-if="
                    $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.wallets
                "
                :href="$whitelabel.order.more_info_urls.wallets"
            >
                {{ $t('order.components.wallets_message.learn_more') }}
            </a>
        </div>
        <OrderTicketActions :show-download-button="false" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import CardHeader from './CardHeader.vue';
import OrderTicketActions from './OrderTicket/OrderTicketActions.vue';

@Component({
    components: {
        CardHeader,
        OrderTicketActions,
    },
})
export default class WalletsMessage extends Vue {}
</script>

<style lang="scss" scoped>
.wallets-message {
    text-align: center;
    margin-bottom: var(--ot-spacing-lg);

    &__content {
        margin: var(--ot-spacing-default) 3rem;
        & a {
            color: var(--ot-shop-color-brand);
        }
    }
}
</style>
