<template>
    <div class="order-ticket-status">
        <div class="order-ticket-status__content">
            <template v-if="simplifiedStatus === 'cancelled'">
                <div
                    v-if="isCancelled"
                    class="order-ticket-status__text"
                >
                    {{
                        $t('order.components.order_ticket_status.cancelled', {
                            date: ticket.invalidated_since ? $l.dateLong(ticket.invalidated_since) : '?',
                        })
                    }}
                </div>
                <div
                    v-else
                    class="order-ticket-status__text"
                >
                    {{ $t('order.components.order_ticket_status.invalid') }}
                </div>
            </template>
            <template v-else>
                <i
                    v-if="isSealed || isLocked"
                    class="order-ticket-status__icon oti oti-lock is-tiny"
                />
                <div
                    v-if="isLocked"
                    class="order-ticket-status__text"
                >
                    {{ $t('order.components.order_ticket_status.locked') }}
                </div>
                <div
                    v-else-if="isSealed"
                    class="order-ticket-status__text"
                >
                    {{
                        $t('order.components.order_ticket_status.sealed', {
                            date: ticket.retrievable_after ? $l.dateTime(
                                new Date(ticket.retrievable_after * 1000),
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                            ) : '',
                        })
                    }}
                </div>
                <div
                    v-else-if="needsPersonalization"
                    class="order-ticket-status__text"
                >
                    {{
                        $t(
                            'order.components.order_ticket_status.personalization'
                        )
                    }}
                </div>
                <div
                    v-else-if="refundInProgress"
                    class="order-ticket-status__text"
                >
                    {{
                        $t('order.components.order_ticket_status.refund', {
                            date: ticket.invalidated_since ? $l.dateLong(ticket.invalidated_since) : '?',
                        })
                    }}
                </div>
                <div
                    v-else-if="isResold"
                    class="order-ticket-status__text"
                >
                    {{
                        $t('order.components.order_ticket_status.resold', {
                            date: ticket.invalidated_since ? $l.dateLong(ticket.invalidated_since) : '?',
                        })
                    }}
                </div>
                <div
                    v-else-if="isRendering"
                    class="order-ticket-status__text"
                >
                    {{ $t('order.components.order_ticket_status.rendering') }}
                </div>
                <div
                    v-else-if="isInvalid"
                    class="order-ticket-status__text"
                >
                    {{ $t('order.components.order_ticket_status.invalid') }}
                </div>
            </template>
        </div>
        <template v-if="simplifiedStatus === 'cancelled'">
            <a
                v-if="
                    isCancelled &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.cancelled
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.cancelled"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.invalid
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.invalid"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
        </template>
        <template v-else>
            <a
                v-if="
                    isLocked &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.locked
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.locked"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    isSealed &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.sealed
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.sealed"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    needsPersonalization &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.personalization
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.personalization"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    refundInProgress &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.refund
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.refund"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    isResold &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.resold
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.resold"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    isRendering &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.rendering
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.rendering"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
            <a
                v-else-if="
                    isInvalid &&
                        $whitelabel.order.more_info_urls &&
                        $whitelabel.order.more_info_urls.invalid
                "
                class="order-ticket-status__link"
                target="_blank"
                :href="$whitelabel.order.more_info_urls.invalid"
            >
                {{ $t('order.components.order_ticket_status.more_info') }}
            </a>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { IOrderTicket } from '@openticket/lib-order';

@Component
export default class OrderTicketStatus extends Vue {

    @Prop() ticket!: IOrderTicket;

    @Prop({ default: false }) needsPersonalization!: boolean;
    @Prop({ default: false }) refundInProgress!: boolean;
    @Prop({ default: false }) isSealed!: boolean;
    @Prop({ default: false }) isRendering!: boolean;
    @Prop({ default: false }) isResold!: boolean;
    @Prop({ default: false }) isInvalid!: boolean;
    @Prop({ default: false }) isCancelled!: boolean;
    @Prop({ default: false }) isLocked!: boolean;
    @Prop({ default: null }) simplifiedStatus!:
        | 'paid'
        | 'pending'
        | 'cancelled'
        | null;

}
</script>

<style lang="scss" scoped>
.order-ticket-status {
    width: 100%;
    border-radius: var(--ot-card-border-radius);
    font-size: 0.75rem;
    padding: var(--ot-input-padding-y) 1.25rem;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: space-between;

    &__icon {
        margin-right: var(--ot-spacing-2xs);
    }

    &__content {
        display: flex;
    }

    &__link,
    &__text,
    &__content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__link {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
