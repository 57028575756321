import { isOtError, OtError } from '@openticket/lib-log';

export default class VueError extends OtError {

    constructor(message: string, cause: Error, slug: string = 'osp.error') {
        super('VueError', slug, message || '');

        this.captureStackTrace(VueError);
        // The old way of defining the slug
        this.addContext('old_slug', VueError.oldSlug(message || '', cause));
        if (isOtError(cause)) {
            this.causedBy(cause);
        }
    }

    private static oldSlug(message: string, cause: Error): string {
        // Note: We cannot return a proper slug, because the slug is used in RefundRequestCard.vue (Backwards compatibility)
        return `Vue error: ${message} , ${cause.toString()}`;
    }

}
