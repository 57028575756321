<template>
    <div class="order-item-metadata-form-item">
        <div
            v-for="(meta, i) in metadata"
            :key="meta.guid"
        >
            <div
                v-if="
                    !(meta.is_complete && meta.metadata.name === 'seat_label')
                "
                class="order-item-metadata-form-item__input"
            >
                <MetaInput
                    :ref="`meta_input_${i}`"
                    :meta="meta"
                    :disabled="!!meta.is_complete"
                />
            </div>
            <div
                v-else
                class="order-item-metadata-form-item__text"
            >
                <span class="ot-text-tiny">{{
                    meta.metadata.translateName
                }}</span>
                <h4>{{ meta.value }}</h4>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import type { IOrderMetaData } from '@openticket/lib-order';
import { Prop } from 'vue-property-decorator';

@Component
export default class OrderTicketMetadataForm extends Vue {

    @Prop()
    private metadata!: IOrderMetaData[];

}
</script>

<style lang="scss" scoped>
.order-item-metadata-form-item {
    &__input,
    &__text {
        padding: 0 1.25rem;
        margin-bottom: 1rem;
    }

    &__input:last-child {
        margin-bottom: 0;
    }
}
</style>
