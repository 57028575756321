<template>
    <div
        class="card-section"
        :class="{
            'card-section__separator': separator,
            'card-section__accent': accent,
            'card-section__highlight-error': highlightError,
        }"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CardSection extends Vue {

    @Prop({ type: Boolean, default: false })
        separator!: boolean;

    @Prop({ type: Boolean, default: false })
        accent!: boolean;

    @Prop({ type: Boolean, default: false })
        highlightError!: boolean;

}
</script>

<style lang="scss" scoped>
.card-section {
    padding: 2.5rem;

    &__accent {
        background: var(--ot-shop-color-box-accent);
    }

    &__separator:not(:last-child) {
        border-bottom: 1px solid var(--ot-shop-color-box-accent);
    }

    &__highlight-error {
        box-shadow: 0 0 0 3px var(--ot-color-accent-orange-dark);
    }
}
</style>
