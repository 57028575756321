<template>
    <CardSection
        class="card-header"
        :separator="separator"
        :accent="accent"
    >
        <i
            v-if="icon"
            class="card-header__icon oti"
            :class="icon"
        />

        <component
            :is="heading"
            class="card-header__title"
        >
            {{
                $t(title)
            }}
        </component>

        <span
            v-if="$scopedSlots.label || label"
            class="card-header__label"
        >
            <slot name="label">{{ label ? $t(label) : '' }}</slot>
        </span>
    </CardSection>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardSection from './CardSection.vue';

@Component({
    components: { CardSection },
})
export default class CardHeader extends Vue {

    @Prop({ default: null })
        icon!: null | string;

    @Prop({ default: 'h3' })
        heading!: null | string;

    @Prop({ required: true })
        title!: string;

    @Prop({ default: null })
        label!: null | string;

    @Prop({ type: Boolean, default: false })
        separator!: boolean;

    @Prop({ type: Boolean, default: false })
        accent!: boolean;

}
</script>

<style lang="scss" scoped>
.card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__icon {
        font-size: 2rem;
        margin: 0 0 0.5rem 0;
    }

    &__label {
        display: block;
        margin: 0.5rem 0 0 0;
        font-size: 0.8125rem;
        line-height: 1.25rem;
    }

    &.card-section {
        padding-top: 1.25rem;
        padding-bottom: 2rem;
    }
}
</style>
