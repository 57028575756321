<template>
    <div
        v-if="hasInfo"
        class="ot-card has-no-padding organiser-info"
    >
        <div class="organiser-info__header">
            <span class="ot-text-small">
                {{ $t('order.components.organiser_info.title') }}
            </span>
        </div>

        <div class="organiser-info__content">
            <h4>{{ event.name }}</h4>
            <a
                v-if="
                    event.visitor_contact_url &&
                        event.visitor_contact_url.length > 0
                "
                :href="event.visitor_contact_url"
                target="_blank"
            >
                <!-- Todo: replace this label with a more sensible element -->
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label class="ot-text-small">
                    <i class="oti oti-link is-small" />
                    {{ hostname }}
                </label>
            </a>
            <a
                v-if="
                    event.visitor_contact_email &&
                        event.visitor_contact_email.length > 0
                "
                :href="`mailto:${event.visitor_contact_email}`"
            >
                <!-- Todo: replace this label with a more sensible element -->
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label class="ot-text-small">
                    <i class="oti oti-inbox-open is-small" />
                    {{ event.visitor_contact_email }}
                </label>
            </a>
            <a
                v-if="
                    event.visitor_contact_phone &&
                        event.visitor_contact_phone.length > 0
                "
                :href="`tel:${event.visitor_contact_phone}`"
            >
                <!-- Todo: replace this label with a more sensible element -->
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label class="ot-text-small">
                    <i class="oti oti-call is-small" />
                    {{ event.visitor_contact_phone }}
                </label>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import type { IOrderEvent } from '@openticket/lib-order';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class OrganiserInfo extends Vue {

    @Prop() event!: IOrderEvent;

    get hasInfo(): boolean {
        if (
            (this.event.visitor_contact_url
                && this.event.visitor_contact_url.length)
            || (this.event.visitor_contact_email
                && this.event.visitor_contact_email.length)
            || (this.event.visitor_contact_phone
                && this.event.visitor_contact_phone.length)
        ) {
            return true;
        }
        return false;
    }

    get hostname(): string {
        if (this.event.visitor_contact_url) {
            return new URL(this.event.visitor_contact_url).host;
        }
        return '';
    }

}
</script>

<style lang="scss" scoped>
.organiser-info {
    margin-bottom: 1rem;

    &__header {
        padding: 1.25rem 2.5rem;
        text-align: center;
        border-bottom: 2px solid var(--ot-shop-color-box-accent);
    }

    &__content {
        text-align: center;
        padding: 1.25rem;

        label {
            display: block;
            margin-top: 0.5rem;
            cursor: pointer;
            color: var(--ot-shop-color-foreground);

            .oti {
                vertical-align: middle;
                margin-right: 0.25rem;
            }
        }
    }
}
</style>
