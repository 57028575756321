<template>
    <div
        v-if="products.length"
        class="ot-card has-no-padding products"
    >
        <h5 class="products__title">
            <i class="oti oti-shop" />{{ $tc('order.components.products.title', products.length) }}
        </h5>
        <div class="products__content">
            <div
                v-for="(product, i) in products"
                :key="product.guid"
                class="products__item"
            >
                <OrderProduct
                    :product="product"
                    :opened="i === opened"
                    :locked="isLocked"
                    @toggle="toggle(i)"
                />
            </div>
        </div>
        <!--<div class="products__footer" v-if="shopUrl">-->
        <!--    <a :href="shopUrl" target="_blank">-->
        <!--        <button class="ot-button is-outline">-->
        <!--            <i class="ot-button-icon&#45;&#45;left oti oti-shop"></i>-->
        <!--            {{ $t('order.components.products.order_more_button') }}-->
        <!--        </button>-->
        <!--    </a>-->
        <!--</div>-->
    </div>
</template>

<script lang="ts">
import type { IOrderGlobalProduct } from '@openticket/lib-order';
import Vue from 'vue';
import Component from 'vue-class-component';
import OrderProduct from './OrderProduct.vue';

@Component({
    components: {
        OrderProduct,
    },
})
export default class Products extends Vue {

    opened = 0;

    get isLocked(): boolean {
        return this.$order.data.status !== 'paid';
    }

    get products(): IOrderGlobalProduct[] {
        return this.$order.data.global_products.filter(
            (product: IOrderGlobalProduct) => !product.product.class
                || ![ 'RefundProtect', 'Co2Compensate', 'CoverGenius' ].includes(
                    product.product.class,
                ),
        );
    }

    get shopUrl(): string | undefined {
        return this.$whitelabel.shop.url + this.$order.data.shop_id;
    }

    toggle(index: number): void {
        if (this.opened === index) {
            this.opened = -1;
        } else {
            this.opened = index;
        }
    }

}
</script>

<style lang="scss" scoped>
.products {
    margin-bottom: 1rem;

    &__title {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.25rem 0;

        i {
            margin-right: 0.5rem;
        }
    }

    &__content {
        padding: 0 1.25rem;
        padding-bottom: 0.75rem;
    }

    &__item {
        margin-bottom: 0.5rem;
    }

    &__footer {
        text-align: center;
        padding: 1.25rem;
        border-top: 2px solid var(--ot-shop-color-box-accent);
    }
}
</style>
