<template>
    <div class="ot-card has-no-padding order-details">
        <div
            class="order-details__header"
            :class="{ open }"
            role="button"
            tabindex="0"
            @click="open = !open"
            @keydown.enter="open = !open"
            @keydown.space="open = !open"
        >
            <div class="order-details__header__content">
                <h3
                    :key="
                        `order-details__header__content.events.length.${$order.data.events.length}`
                    "
                >
                    {{
                        $tc(
                            'order.components.order_details.header.title',
                            $order.data.events.length
                        )
                    }}
                </h3>
                <span
                    :key="
                        `order-details__header__content.itemCount.${itemCount}`
                    "
                    class="ot-text-small-strong"
                >{{
                    $tc(
                        'order.components.order_details.header.subtitle',
                        itemCount
                    )
                }}</span>
            </div>
            <div class="order-details__header__action">
                <span class="ot-text-small-strong">{{
                    $t('order.components.order_details.header.tax')
                }}</span>
                <h4
                    :key="
                        `order-details__header__action.payment.finn_price.${paymentFinnPrice}`
                    "
                >
                    {{
                        $l.currency(paymentFinnPrice, $order.data.shop.currency)
                    }}
                    <i
                        class="oti"
                        :class="open ? 'oti-drop-up' : 'oti-drop-down'"
                    />
                </h4>
            </div>
        </div>
        <Collapse>
            <div v-show="open">
                <BookerInfo />
                <div
                    v-for="event in $order.data.events"
                    :key="event.guid"
                    class="order-details__event"
                >
                    <div class="order-details__event__header">
                        <div class="order-details__event__header__content">
                            <h6>{{ event.name }}</h6>

                            <div
                                v-if="showEventDate && event.start && event.end"
                                class="ot-text-tiny"
                            >
                                {{
                                    $l.dateTimeRange(
                                        event.start,
                                        event.end,
                                        Intl.DateTimeFormat().resolvedOptions()
                                            .timeZone
                                    )
                                }}
                            </div>
                            <div
                                v-if="showLocation"
                                class="ot-text-tiny"
                            >
                                {{ event.location.name }}
                            </div>
                        </div>
                    </div>
                    <div class="order-details__event__content">
                        <div
                            v-for="ticket in event.tickets"
                            :key="ticket.guid"
                        >
                            <OrderDetailsTicket
                                :ticket="ticket"
                                :show-qr-codes="showQrCodes"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="$order.data.global_products.length"
                    class="order-details__products"
                >
                    <h3 class="has-margin">
                        <i class="oti oti-shop" />{{
                            $t('order.components.order_details.products.title')
                        }}
                    </h3>
                    <div
                        v-for="product in $order.data.global_products"
                        :key="product.guid"
                        class="order-details__products__item"
                    >
                        <h6 class="order-details__products__item__content">
                            {{ product.product.name }}
                        </h6>
                        <h6
                            :key="
                                `order-details__products__item__price.product.finn_price.${product.finn_price}`
                            "
                            class="order-details__products__item__price"
                        >
                            {{
                                $l.currency(
                                    product.finn_price,
                                    $order.data.shop.currency
                                )
                            }}
                        </h6>
                    </div>
                </div>
                <div class="order-details__total">
                    <div class="order-details__total__spacer" />
                    <div class="order-details__total__container">
                        <div class="ot-text-tiny order-details__total__line">
                            <div class="order-details__total__line__label">
                                {{
                                    $t(
                                        'order.components.order_details.sum.subtotal'
                                    )
                                }}
                            </div>
                            <div class="order-details__total__line__price">
                                {{
                                    $l.currency(
                                        paymentFinnValue,
                                        $order.data.shop.currency
                                    )
                                }}
                            </div>
                        </div>
                        <div class="ot-text-tiny order-details__total__line">
                            <div class="order-details__total__line__label">
                                {{
                                    $t(
                                        'order.components.order_details.sum.service_fee'
                                    )
                                }}
                            </div>
                            <div
                                :key="
                                    `order-details__total__line__price.payment.finn_service_fee.${paymentFinnServiceFee}`
                                "
                                class="order-details__total__line__price"
                            >
                                {{
                                    $l.currency(
                                        paymentFinnServiceFee,
                                        $order.data.shop.currency
                                    )
                                }}
                            </div>
                        </div>
                        <div class="order-details__total__line is-total">
                            <div
                                class="
                                    ot-text-small-strong
                                    order-details__total__line__label
                                "
                            >
                                {{
                                    $t(
                                        'order.components.order_details.sum.total'
                                    )
                                }}
                            </div>
                            <h3
                                :key="
                                    `order-details__total__line__price.payment.finn_price.${paymentFinnPrice}`
                                "
                                class="order-details__total__line__price"
                            >
                                {{
                                    $l.currency(
                                        paymentFinnPrice,
                                        $order.data.shop.currency
                                    )
                                }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="order-details__booker">
                    <span class="order-details__booker__name ot-text-small">{{
                        $order.data.fullname
                    }}</span>
                    <span class="order-details__booker__email ot-text-small">{{
                        $order.data.email
                    }}</span>
                    <span
                        class="order-details__booker__payment-id ot-text-small"
                    >
                        {{
                            $t(
                                'order.components.order_details.receipt.payment_id'
                            )
                        }}
                        {{
                            payment && payment.payment_id
                                ? payment.payment_id
                                : $t(
                                    'order.components.order_details.receipt.no_payment_id'
                                )
                        }}
                    </span>
                </div>
            </div>
        </Collapse>
        <div
            v-if="$order.data.status === 'paid' && $order.data.receipt_link"
            class="order-details__receipt"
        >
            <a
                target="_blank"
                :href="$order.data.receipt_link"
            >
                <button class="ot-button">
                    <i class="ot-button-icon--left oti oti-file" />{{
                        $t(
                            'order.components.order_details.receipt.download_receipt_button'
                        )
                    }}
                </button>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { IOrderPayment } from '@openticket/lib-order';
import BookerInfo from './BookerInfo.vue';
import OrderDetailsTicket from './OrderDetailsTicket.vue';

@Component({
    components: {
        OrderDetailsTicket,
        BookerInfo,
    },
})
export default class OrderDetails extends Vue {

    open = false;

    @Prop({ default: false, type: Boolean })
        showQrCodes!: boolean;

    get payment(): IOrderPayment | undefined {
        return Object.values(this.$order.data.payment_map)[0];
    }

    get paymentFinnPrice(): number {
        return this.payment ? this.payment.finn_price : 0;
    }

    get paymentFinnValue(): number {
        return this.payment ? this.payment.finn_value : 0;
    }

    get paymentFinnServiceFee(): number {
        return this.payment ? this.payment.finn_service_fee : 0;
    }

    get itemCount(): number {
        return (
            Object.keys(this.$order.data.ticket_map).length
            + Object.keys(this.$order.data.global_product_map).length
        );
    }

    get showEventDate(): boolean {
        if (!this.$settings) {
            return true;
        }
        return !!this.$settings.static.order.components.eventCard.showEventDate;
    }

    get showLocation(): boolean {
        if (!this.$settings) {
            return true;
        }
        return !!this.$settings.static.order.components.eventCard.showLocation;
    }

}
</script>

<style lang="scss" scoped>
.order-details {
    margin-bottom: 1rem;

    &__header {
        display: flex;
        padding: var(--ot-card-padding);
        border-bottom: 2px solid transparent;
        cursor: pointer;
        align-items: center;

        &.open {
            border-bottom: 2px solid var(--ot-shop-color-box-accent);
        }

        &__content {
            flex: 1;
        }

        &__action {
            text-align: right;

            h4 {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                i {
                    margin-left: 0.5rem;
                }
            }
        }

        .ot-text-tiny {
            opacity: 0.5;
        }
    }

    &__event {
        padding: var(--ot-card-padding);
        border-bottom: 2px solid var(--ot-shop-color-box-accent);

        &__header {
            padding-bottom: var(--ot-card-padding);
            display: flex;
            align-items: center;

            &__content {
                flex: 1;
            }
        }

        .ot-text-tiny {
            opacity: 0.5;
        }
    }

    &__products {
        padding: var(--ot-card-padding);
        border-bottom: 2px solid var(--ot-shop-color-box-accent);

        h3 {
            display: flex;
            align-items: center;

            i {
                margin-right: 0.5rem;
            }
        }

        &__item {
            display: flex;
            border-bottom: 1px solid var(--ot-shop-color-box-accent);
            padding: 0.5rem 0;

            &__content {
                flex: 1;
            }

            &__price {
                width: auto;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__total {
        padding: var(--ot-card-padding);
        border-bottom: 2px solid var(--ot-shop-color-box-accent);
        display: flex;

        &__spacer {
            flex: 1;
        }

        &__line {
            display: flex;
            align-items: center;

            &__label {
                opacity: 0.5;
                flex: 1;
            }

            &__price {
                opacity: 0.8;
                width: auto;
                margin-left: 1rem;
            }

            &.is-total {
                margin-top: 1rem;
            }

            &.is-total & {
                &__label {
                    opacity: 1;
                }
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &__booker {
        padding: var(--ot-card-padding) var(--ot-card-padding) 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__payment-id {
            opacity: 0.5;
        }

        &:last-child {
            padding-bottom: var(--ot-card-padding);
        }
    }

    &__receipt {
        padding: var(--ot-card-padding);
        text-align: center;
        border-top: 2px solid var(--ot-shop-color-box-accent);
    }
}
</style>
