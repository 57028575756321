<template>
    <div class="ot-input-date-alt">
        <div class="ot-select years">
            <!-- Todo: refactor the parent label component to properly point to this form field -->
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <select
                v-model="year"
                required
                :disabled="disabled"
            >
                <option
                    v-for="year in years"
                    :key="year"
                    :value="year"
                >
                    {{ year }}
                </option>
            </select>
            <span class="ot-select-focus" />
        </div>
        <div class="ot-select months">
            <!-- Todo: refactor the parent label component to properly point to this form field -->
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <select
                v-model="month"
                required
                :disabled="disabled"
            >
                <option
                    v-for="month in months"
                    :key="month"
                    :value="month"
                >
                    {{ $localization.locale.monthNamesWide[month - 1] }}
                </option>
            </select>
            <span class="ot-select-focus" />
        </div>
        <div class="ot-select days">
            <!-- Todo: refactor the parent label component to properly point to this form field -->
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <select
                v-model="day"
                required
                :disabled="disabled"
            >
                <option
                    v-for="day in days"
                    :key="day"
                    :value="day"
                >
                    {{ day }}
                </option>
            </select>
            <span class="ot-select-focus" />
        </div>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { OtInputBase } from '@openticket/vue-input';

// source: https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
// Month here is 1-indexed (January is 1, February is 2, etc). This is
// because we're using 0 as the day so that it returns the last day
// of the last month, so you have to add 1 to the month number
// so it returns the correct amount of days
function daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

@Component
export default class DateAlt extends OtInputBase<string> {

    @Prop({ default: [] }) rules!: string[];

    @Prop({ default: null }) before!: Date | null;

    @Prop({ default: null }) after!: Date | null;

    year: number | null = null;

    month: number | null = null;

    day: number | null = null;

    created(): void {
        this.onValueChangeDate();
    }

    private get years(): number[] {
        const max = this.before
            ? this.before.getFullYear()
            : new Date().getFullYear() + 20;

        const min = this.after
            ? this.after.getFullYear()
            : new Date().getFullYear() - 150;

        const years: number[] = [];

        for (let i = max; i >= min; i--) {
            years.push(i);
        }

        return years;
    }

    private get months(): number[] {
        let months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];

        if (this.before && this.before.getFullYear() === this.year) {
            const maxMonth = this.before.getMonth();
            months = months.slice(0, maxMonth + 1);
        }

        if (this.after && this.after.getFullYear() === this.year) {
            const minMonth = this.after.getMonth();
            months = months.slice(minMonth);
        }

        return months;
    }

    private get days(): number[] {
        if (!this.year || !this.month) {
            return [];
        }

        const max = this.before
            && this.before.getFullYear() === this.year
            && this.before.getMonth() + 1 === this.month
            ? this.before.getDate()
            : daysInMonth(this.month, this.year);

        const min = this.after
            && this.after.getFullYear() === this.year
            && this.after.getMonth() + 1 === this.month
            ? this.after.getDate()
            : 1;

        const days: number[] = [];

        for (let i = max; i >= min; i--) {
            days.push(i);
        }

        return days;
    }

    @Watch('value')
    private onValueChangeDate() {
        if (!this.value) {
            this.year = null;
            this.month = null;
            this.day = null;
            return;
        }

        const split = this.value.split('-');

        const newYear = parseInt(split[0], 10);
        if (this.year !== newYear) {
            this.year = newYear;
        }

        const newMonth = parseInt(split[1], 10);
        if (this.month !== newMonth) {
            this.month = newMonth;
        }

        const newDay = parseInt(split[2], 10);
        if (this.day !== newDay) {
            this.day = newDay;
        }
    }

    @Watch('year')
    @Watch('month')
    @Watch('day')
    private onChangeDate() {
        if (this.year && this.month && this.day) {
            const date = [
                this.year,
                zeroPad(this.month, 2),
                zeroPad(this.day, 2),
            ].join('-');
            this.$emit('input', date);
        }
    }

    @Watch('months')
    private checkMonth(): void {
        if (this.month && !this.months.includes(this.month)) {
            this.month = null;
        }
    }

    @Watch('days')
    private checkDay(): void {
        if (this.day && !this.days.includes(this.day)) {
            this.day = null;
        }
    }

}
</script>

<style lang="scss" scoped>
.ot-input-date-alt {
    display: flex;

    .ot-select {
        position: relative;
        display: grid;
        grid-template-areas: 'select';
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: var(--ot-input-height);
        min-width: 2ch;
        padding: 0;
        border: var(--ot-input-border);
        border-radius: var(--ot-input-radius);
        background: var(--ot-input-background);
        box-shadow: var(--ot-input-shadow);
        color: var(--ot-input-color);
        font-family: var(--ot-text-font-family);
        font-size: 1em;
        font-weight: 500;
        line-height: 1.1;
        caret-color: var(--ot-input-focus-color);
        cursor: pointer;
        transition: var(--ot-transition-default), background-image 0ms;

        .ot-select-focus {
            display: none;
        }

        & > select {
            // A reset of styles, including removing the default dropdown arrow
            appearance: none;
            -webkit-appearance: none;
            padding: var(--ot-input-padding);
            padding-right: calc(3 * var(--ot-spacing-sm));
            margin: 0;
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            cursor: inherit;
            grid-area: select;
        }

        &::after {
            content: '';
            width: var(--ot-spacing-sm);
            height: calc(var(--ot-spacing-sm) / 2);
            margin-right: var(--ot-spacing-sm);
            background-color: var(--ot-color-core-foreground-secondary);
            clip-path: polygon(
                54% 66%,
                14% 4%,
                4% 32%,
                48% 100%,
                59% 100%,
                100% 32%,
                93% 4%,
                54% 66%
            );
            grid-area: select;
            justify-self: end;
        }

        &:not(input):not(.ot-textarea):focus-within {
            border-color: var(--ot-input-border-color);
            box-shadow: 0 0 0 1px var(--ot-input-border-color-focus);
            outline: none;
        }

        & > select:focus + .ot-select-focus,
        &:focus-within + .ot-select-focus {
            display: block;
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;

            border: var(--ot-input-border-width) var(--ot-input-border-style)
                var(--ot-input-border-color-focus);
            border-radius: inherit;
            box-shadow: 0 0 0 1px var(--ot-input-border-color-focus);
            outline: none;
        }

        &[disabled] {
            border-color: var(--ot-input-color-disabled);
            color: var(--ot-input-color-disabled-invert);
            background-color: var(--ot-input-color-disabled);
            box-shadow: 0 0 0 1px var(--ot-input-color-disabled);
            cursor: not-allowed;
        }
    }

    .years {
        flex: 2;
    }

    .months {
        flex: 2;
    }

    .days {
        flex: 1;
    }

    & > * {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
