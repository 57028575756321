<template>
    <OtPromoBlockList :promo-blocks="promoBlocks" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import urlJoin from 'url-join';
import axios from 'axios';
import type { PromoBlockModel } from '@openticket/vue-promoblock-components';
import { Log, send } from '@openticket/lib-log';
import { StringMessage } from '@openticket/lib-order';

@Component
export default class PromoBlocks extends Vue {

    promoBlocks: PromoBlockModel[] | null = null;

    async created(): Promise<void> {
        if (!import.meta.env.VITE_PROMO_BLOCK_URL) {
            return;
        }
        const shopId = this.$order.data.shop_id;

        const path = urlJoin(
            import.meta.env.VITE_PROMO_BLOCK_URL,
            'shop',
            encodeURIComponent(shopId),
            'promo_block',
        );

        try {
            const { data } = await axios.get<{data: null | {data: unknown[]}}>(path);
            if (data && data.data && Array.isArray(data.data)) {
                this.promoBlocks = data?.data.filter((possibleBlock: unknown): possibleBlock is PromoBlockModel => !!possibleBlock
                    && typeof possibleBlock === 'object' && (
                    'image' in possibleBlock
                        || 'logo' in possibleBlock
                        || 'title' in possibleBlock
                        || 'button' in possibleBlock
                        || 'link' in possibleBlock
                ));
            } else {
                this.promoBlocks = null;
            }
        } catch (e) {
            send(
                new StringMessage(
                    'osp.promo_blocks.created.failed_to_fetch',
                    'Failed to fetch promo blocks',
                    { error: e },
                ),
                Log.Error,
            );
        }
    }

}
</script>
