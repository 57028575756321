<template>
    <div class="order-view__content__status">
        <BrandedHeader
            :title="title"
            hide-logo
        />

        <OrderMessages />

        <BackToShop />

        <EventCard
            v-for="event in $order.data.events"
            :key="event.guid"
            :class="`ot-elem-event-${event.guid}`"
            :event="event"
            :opened-card="openedCard"
            show-qr-codes
            hide-download-actions
            @toggle="toggleCard"
            @next="closeCard"
        />

        <PrintOrder />

        <OrderDetails show-qr-codes />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import type { TranslateResult } from '@openticket/vue-localization';
import SimplifyStatus from '../../../utils/simplify_status';

import BackToShop from '../components/BackToShop.vue';
import EventCard from '../components/EventCard.vue';
import OrderDetails from '../components/OrderDetails.vue';
import PrintOrder from '../components/PrintOrder.vue';
import OrderMessages from '../components/OrderMessages.vue';

@Component({
    components: {
        PrintOrder,
        BackToShop,
        EventCard,
        OrderDetails,
        OrderMessages,
    },
})
export default class OrderPosView extends Vue {

    private openedCard: string | null = null;

    get simplifiedStatus(): 'paid' | 'pending' | 'cancelled' | null {
        return SimplifyStatus(this.$order.data.status);
    }

    /**
     *  Used to get the title for the branded header
     *  component, based on the status of the order.
     */
    get title(): TranslateResult | null {
        switch (this.simplifiedStatus) {
            case 'paid':
                return this.$t('order.components.order.status.paid');

            case 'pending':
                return this.$t('order.components.order.status.pending');

            case 'cancelled':
                return this.$t('order.components.order.status.cancelled');

            default:
                return null;
        }
    }

    toggleCard(guid: string): void {
        if (this.openedCard !== guid) {
            this.openedCard = guid;
        } else {
            this.openedCard = null;
        }
    }

    closeCard(): void {
        this.openedCard = null;
    }

}
</script>
