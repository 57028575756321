<template>
    <div class="branded-header">
        <div
            v-if="!hideLogo"
            class="branded-header__logo"
        >
            <Logo />
        </div>
        <h1 v-if="title && title.length">
            {{ $t(title) }}
        </h1>
        <div class="branded-header__content">
            <slot name="content" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BrandedHeader extends Vue {

    @Prop({ type: String })
        title!: string;

    @Prop({ default: false, type: Boolean })
        hideLogo!: boolean;

}
</script>

<style lang="scss" scoped>
.branded-header {
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 0 0.875rem;

    &__logo img {
        height: 1.5rem;
        margin-bottom: 1rem;
    }
}
</style>
