import type {
    CookieKeys,
    PartialCombinedMessages,
    CookieManager,
} from '../../composables/cookies';

export function getCookieMessages(
    vm: Vue,
): null | PartialCombinedMessages<CookieKeys> {
    if (!vm.$te('common.cookie_modal')) {
        return null;
    }

    return vm.$t('common.cookie_modal') as PartialCombinedMessages<CookieKeys>;
}

export async function initCookies(
    manager: CookieManager,
    messages: null | PartialCombinedMessages<CookieKeys>,
): Promise<void> {
    manager.setMessages(messages);

    await manager.load();

    await manager.complete;
}
