<template>
    <CardSection
        class="refund-request-donate"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <span class="refund-request-donate__label">{{
            $t('order.components.refund_request_card.donate.label')
        }}</span>

        <ot-input-radio-group
            v-model="selectedDonateOption"
            class="refund-request-donate__options"
            :options="donateOptions"
            :class="{ disabled }"
            :disabled="disabled"
        />
    </CardSection>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import type { IOrderRefundSetting } from '@openticket/lib-order';
import CardSection from '../CardSection.vue';
import RefundRequestCardSection from './RefundRequestCardSection.vue';

@Component({ components: { CardSection } })
export default class RefundRequestDonate extends RefundRequestCardSection {

    @Prop({ required: true })
        refundSetting!: IOrderRefundSetting;

    @PropSync('donateOption', { required: true, default: null })
        selectedDonateOption!: number | null;

    get donateOptions(): { [key: number]: { label: string } } {
        if (!this.refundSetting.refund_donate_enabled) {
            return {};
        }

        const donateValues: number[] = this.refundSetting.refund_donate_options
            .split(',')
            .map((val: string) => parseInt(val.trim(), 10));

        donateValues.unshift(0);

        const options: { [key: number]: { label: string } } = {};

        for (const val of donateValues) {
            options[val] = {
                label: this.$tc(
                    'order.components.refund_request_card.donate.amount',
                    val,
                    { percentage: this.$l.percentage100(val) },
                ),
            };
        }

        return options;
    }

}
</script>

<style lang="scss" scoped>
.refund-request-donate {
    &__label {
        display: block;
        margin: 0 0 2.5rem 0;
        font-size: 0.8125rem;
        line-height: 1.25rem;
    }

    &__options::v-deep {
        & > .ot-radio {
            display: inline-block;
            width: 50%;
            text-align: initial;

            & > .ot-radio-label::before {
                background-color: var(--ot-color-core-white);
            }

            &[disabled],
            &.disabled {
                pointer-events: none;
                cursor: not-allowed;
            }

            &[disabled] > input[type='radio'],
            &.disabled > input[type='radio'],
            & > input[type='radio'][disabled],
            & > input[type='radio'].disabled {
                & + label.ot-radio-label::after {
                    background-color: var(--ot-input-color-disabled-invert);
                }
            }
        }

        &.ot-radio-group.disabled,
        &.ot-radio-group[disabled] {
            pointer-events: none;

            .ot-radio > input[type='radio'] + label.ot-radio-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
