<template>
    <div class="collapse">
        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
        >
            <slot />
        </transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Collapse extends Vue {

    start(el: HTMLElement): void {
        el.style.height = `${el.scrollHeight}px`;
    }

    end(el: HTMLElement): void {
        el.style.height = '';
    }

}
</script>
