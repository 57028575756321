<template>
    <div class="order-item-actions">
        <div
            v-if="opened && hasContent"
            class="order-item-actions__container"
        >
            <div
                v-if="opened && hasContent"
                class="order-item-actions__action"
            >
                <!-- Todo: replace this label with a more sensible element -->
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label>
                    {{ $t('order.components.order_item.buttons.hide') }}
                </label>
            </div>
        </div>
        <div
            v-else-if="
                !locked &&
                    !needsPersonalization &&
                    !isInvalid &&
                    !isCancelled &&
                    !refundInProgress &&
                    !isSealed
            "
            class="order-item-actions__container"
            role="button"
            tabindex="0"
            @click.capture="preventClickBubbleWhenNotEnabled"
            @keydown.enter="preventClickBubbleWhenNotEnabled"
            @keydown.space="preventClickBubbleWhenNotEnabled"
        >
            <slot name="actions" />
        </div>
        <div
            v-else
            class="order-item-actions__status"
            :class="{
                'needs-personalization': needsPersonalization && !locked,
            }"
        >
            <template v-if="simplifiedStatus === 'cancelled'">
                <div v-if="isCancelled">
                    <i class="oti oti-close" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.cancelled') }}
                    </div>
                </div>
                <div v-else>
                    <i class="oti oti-close" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.invalid') }}
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="locked">
                    <i class="oti oti-lock" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.locked') }}
                    </div>
                </div>
                <div v-else-if="isSealed">
                    <i class="oti oti-lock" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.sealed') }}
                    </div>
                </div>
                <div v-else-if="isResold">
                    <i class="oti oti-shops" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.resold') }}
                    </div>
                </div>
                <div v-else-if="needsPersonalization">
                    <div
                        class="order-item-actions__status__label-personalization"
                    >
                        {{
                            $t(
                                'order.components.order_item.status.personalization'
                            )
                        }}
                    </div>
                </div>
                <div v-else-if="refundInProgress">
                    <i class="oti oti-logout" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.refund') }}
                    </div>
                </div>
                <div v-else>
                    <i class="oti oti-close" />
                    <div class="order-item-actions__status__label">
                        {{ $t('order.components.order_item.status.invalid') }}
                    </div>
                </div>
            </template>
        </div>
        <div
            v-if="hasContent && !isInvalid && !isResold"
            class="order-item-actions__toggle"
        >
            <i
                v-if="!opened"
                class="oti oti-drop-down is-small"
                :class="{
                    'needs-personalization': needsPersonalization && !locked,
                }"
            />
            <i
                v-else
                class="oti oti-drop-up is-small"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class OrderItem extends Vue {

    @Prop() locked!: boolean;
    @Prop() opened!: boolean;
    @Prop() hasContent!: boolean;

    @Prop({ default: false }) isInvalid!: boolean;
    @Prop({ default: false }) isCancelled!: boolean;
    @Prop({ default: false }) isResold!: boolean;
    @Prop({ default: false }) isSealed!: boolean;
    @Prop({ default: false }) refundInProgress!: boolean;
    @Prop({ default: false }) needsPersonalization!: boolean;
    @Prop({ default: false }) walletEnabled!: boolean;
    @Prop({ default: null }) simplifiedStatus!:
        | 'paid'
        | 'pending'
        | 'cancelled'
        | null;

    preventClickBubbleWhenNotEnabled(event: Event): void {
        if (!this.walletEnabled) {
            event.stopPropagation();
        }
    }

}
</script>

<style lang="scss" scoped>
.order-item-actions {
    display: flex;
    align-items: center;
    max-width: 30%;
    justify-content: flex-end;

    &__container {
        display: flex;
        align-items: center;
        text-align: center;
    }

    &__status {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: var(--ot-spacing-2xs);

        &.needs-personalization {
            color: var(--ot-shop-color-brand);
        }

        &__label {
            // ot-content sets flex-grow of headings to 1
            flex-grow: 0;
            font-size: 0.813rem;
            line-height: 1rem;
            font-weight: 500;

            &-personalization {
                font-weight: 500;
                font-size: 0.875rem;
                text-align: right;
            }
        }

        & > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }

        .oti {
            margin-bottom: var(--ot-spacing-xs);
            &:before {
                font-size: 1.2rem;
            }
        }
    }

    &__toggle {
        margin-left: 1rem;
        display: inline-flex;
        align-items: center;

        & .oti.needs-personalization {
            color: var(--ot-shop-color-brand);
        }

        &:hover {
            color: var(--ot-shop-color-brand);
            cursor: pointer;
        }
    }

    &::v-deep {
        .order-item-actions__action {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            margin-left: 1rem;

            & label {
                font-size: 0.813rem;
                line-height: 1;
            }

            &:hover,
            &:hover label {
                color: var(--ot-shop-color-brand);
                cursor: pointer;
            }
        }
    }
}
</style>
