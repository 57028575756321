<template>
    <div
        v-if="showCard"
        class="ot-card refund-protect"
    >
        <div class="refund-protect__shield">
            <img
                src="../../../../assets/images/icons/shield.svg"
                alt="shield icon"
            >
        </div>
        <CardHeader
            heading="h2"
            title="order.components.refund_protect.title"
        >
            <template #label>
                <!-- eslint-disable vue/no-v-html -->
                <!-- Todo: replace this label with a more sensible element -->
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label
                    class="ot-text-small"
                    v-html="
                        $t('order.components.refund_protect.description', {
                            requestUrl: requestUrl,
                        })
                    "
                />
                <!-- eslint-enable vue/no-v-html -->
            </template>
        </CardHeader>
        <p class="reference-name">
            {{ $t("order.components.refund_protect.reference_name") }}
        </p>
        <div
            role="button"
            tabindex="0"
            class="ot-notification is-black"
            @click="copyBookingReference"
            @keydown.enter="copyBookingReference"
            @keydown.space="copyBookingReference"
        >
            <div class="ot-notification-content">
                <div class="ot-notification-content__text-container">
                    <span class="ot-notification-content__sub-text">
                        {{ bookingReference }}
                    </span>
                </div>
                <div class="ot-notification-content__copy-icon">
                    <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 5.5H6V10.5H11V5.5ZM4.5 4V12H12.5V4H4.5Z"
                            fill="white"
                        />
                        <path
                            d="M2 1.5H7V3H8.5V0H0.5V8H3.5V6.5H2V1.5Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <p
            class="reference-name"
            v-html="
                $t('order.components.refund_protect.terms', {
                    termsUrl: termsUrl,
                })
            "
        />
        <!-- eslint-enable vue/no-v-html -->
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import type { IOrderGlobalProduct } from '@openticket/lib-order';
import { copyToClipboard } from '@openticket/lib-utils';
import CardHeader from '../CardHeader.vue';

const productClass = 'RefundProtect';

@Component({
    components: {
        CardHeader,
    },
})
export default class RefundProtect extends Vue {

    get bookingReference(): string {
        return this.$order.data.guid;
    }

    get showCard(): boolean {
        return !!this.$order.data.global_products.find(
            (product: IOrderGlobalProduct) => product.product.class === productClass,
        );
    }

    get requestUrl(): string {
        return (
            `https://tiny.openticket.tech/rpform?bookingReference=${
                this.bookingReference}`
        );
    }

    get termsUrl(): string {
        if (this.$order.data.locale === 'nl_NL') {
            return 'https://tiny.openticket.tech/rptermsnl';
        }

        return 'https://tiny.openticket.tech/rptermsen';
    }

    copyBookingReference(): void {
        copyToClipboard(this.bookingReference);
        this.$notifications.info(
            this.$t('order.components.refund_protect.reference_copied'),
        );
    }

}
</script>

<style lang="scss">
.refund-protect {
    margin-bottom: 1rem;

    &__shield {
        text-align: center;
    }

    .card-section.card-header {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    a {
        color: var(--ot-color-core-brand);
    }

    .reference-name {
        text-align: center;
    }

    .ot-notification {
        border-radius: 180px;
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;

        &-content__info-icon {
            margin-right: 0;
        }
    }
}
</style>
