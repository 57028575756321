/**
 * Note: This file has been copied over from Shop-SDK.
 *
 * We might move this to lib-order in the future.
 */

/**
 *  If the metadata applies to a person it should only be asked once per
 *  person / ticket, if it applies to a product it should be asked for
 *  every product that’s in the cart *(cartProduct)*.
 */
export enum MetadataAppliesTo {
    Person = 'person',
    Product = 'product',
}

export type MetaDataItemValue = number | string | boolean | string[] | null;

/**
 *  Wrapper for a metadata item. Holding a reference to the original
 *  metadata item and the values and errors for the specific case.
 */
export interface BaseMetadataItem<
    T extends MetaDataItemValue = MetaDataItemValue
> {
    /** Reference to the original metaData model. */
    readonly item: PartialMetadata;
    /** A value for the current metadata answer. */
    value: T;
    /**
     *  A Container holding human readable (English) errors
     *  validation errors for the metadata answer.
     */
    errors: string[];
    /** Index to sort lists by * */
    index: number;
}

/**
 *  The type of data the metadata should be answered with. Most are basic data
 *  types and straightforward, some need a bit of clarification.
 *
 *  | Type          | Required input                |
 *  | :------------ | :---------------------------- |
 *  | `enumOther`   | Select or manual input        |
 *  | `values`      | Multiselect                   |
 *
 *  The `seatSelect` is used for [Seats](https://www.seats.io/) integration and are
 *  handled by the API separately, so you can ignore it.
 */
export enum MetadataType {
    Integer = 'integer',
    String = 'string',
    Date = 'date',
    Enum = 'enum',
    EnumOther = 'enumOther',
    Boolean = 'boolean',
    Values = 'values',
    SeatSelect = 'seatSelect',
    Phone = 'phone',
}

/**
 *  The data for metadata and (fixed) booker info; data that should
 *  be asked to and answered by the ticket buyer.
 */
export interface PartialMetadata {
    name: string;
    extra: string[];
    translateName: string;
    translatable: boolean;
    auto_fill_facebook: string;
    type: MetadataType;
    shop_description?: string;
}

/**
 *  The data for metadata; data that should be asked to and answered by the ticket buyer.
 */
export interface Metadata extends PartialMetadata {
    guid: string;
    company_id: string;
    shop_description: string;
    default: null | boolean;
    public: boolean;
    forced: boolean;
    class: null | string;
    applies_to: MetadataAppliesTo;
    /**
     *  If the metadata type is enum, enumOther or values,
     *  these are the parsed (default) possible values.
     */
    options: string[];
}

export interface ValidatableMetadata {
    readonly item: {
        readonly name: string;
        readonly extra: string[];
        readonly translateName?: string;
        readonly type:
            | 'integer'
            | 'string'
            | 'date'
            | 'enum'
            | 'enumOther'
            | 'boolean'
            | 'values'
            | 'seatSelect'
            | 'phone';
    };
    readonly value: unknown;
    errors: string[];
}
