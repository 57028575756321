<template>
    <CardHeader
        :title="$t('order.components.refund_request_card.title')"
        :separator="separator"
        :accent="accent"
        icon="oti-logout"
    >
        <template #label>
            <h4 class="refund-request-card-header__subtitle">
                {{ event.name }}
            </h4>
            <span v-if="expired">{{
                $t('order.components.refund_request_card.info.expired', {
                    status_until: statusUntilDate ? $l.dateTime(
                        statusUntilDate,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                    ) : '?',
                })
            }}</span>
            <span v-else>{{
                $t('order.components.refund_request_card.info.description', {
                    status_until: statusUntilDate ? $l.dateTime(
                        statusUntilDate,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                    ) : '?',
                })
            }}</span>
        </template>
    </CardHeader>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import type { IOrderEvent } from '@openticket/lib-order';
import { Prop } from 'vue-property-decorator';
import CardHeader from '../CardHeader.vue';
import RefundRequestCardSection from './RefundRequestCardSection.vue';

@Component({ components: { CardHeader } })
export default class RefundRequestCardHeader extends RefundRequestCardSection {

    @Prop({ required: true })
        event!: IOrderEvent;

    @Prop({ required: true, default: false, type: Boolean })
        expired!: boolean;

    get statusUntilDate(): string | null {
        return this.event.status_until
        || (this.event.refund_setting && this.event.refund_setting.status_until);
    }

}
</script>

<style lang="scss" scoped>
.refund-request-card-header {
    &__subtitle {
        margin-bottom: 0.5rem;
    }
}
</style>
