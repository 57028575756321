<template>
    <div
        v-if="show"
        class="ot-card has-no-padding sealed-tickets"
    >
        <CardHeader
            icon="oti-lock"
            heading="h2"
            :title="
                $t('order.components.sealed_tickets.title', {
                    whitelabelName,
                    sealedTicketsExplanationUrl,
                })
            "
        >
            <template #label>
                <!-- Todo: replace this label with a more sensible element -->
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label class="ot-text-body">
                    {{
                        $t('order.components.sealed_tickets.subtitle', {
                            whitelabelName,
                            sealedTicketsExplanationUrl,
                        })
                    }}
                </label>
            </template>
        </CardHeader>
        <div class="sealed-tickets__footer">
            <!-- eslint-disable vue/no-v-html -->
            <p
                class="ot-text-tiny"
                v-html="
                    $t('order.components.sealed_tickets.footer', {
                        whitelabelName,
                        sealedTicketsExplanationUrl,
                    })
                "
            />
            <!-- eslint-enable vue/no-v-html -->
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import CardHeader from './CardHeader.vue';

@Component({
    components: {
        CardHeader,
    },
})
export default class SealedTickets extends Vue {

    get show(): boolean {
        const now = Math.floor(Date.now() / 1000); // unix timestamp
        for (const ticket of Object.values(this.$order.data.ticket_map)) {
            // Show if there is any ticket which is sealed in the future
            if (ticket.retrievable_after && ticket.retrievable_after > now) {
                return true;
            }
        }
        return false;
    }

    get whitelabelName(): string {
        return this.$whitelabel.name;
    }

    get sealedTicketsExplanationUrl(): string {
        return (
            this.$whitelabel.order.sealed_tickets_explanation_url
            || this.$whitelabel.website_url
        );
    }

}
</script>

<style lang="scss" scoped>
.sealed-tickets {
    margin-bottom: 1rem;
    overflow: hidden;

    &__footer {
        text-align: center;
        background-color: var(--ot-shop-color-box-accent);
        padding: var(--ot-card-padding);

        p {
            opacity: 0.5;
        }
    }
}
</style>
