/**
 *
 *  Used to simplify the status to paid, pending or cancelled.
 *
 *  @todo Improve status type in OpenTicket SDK lib IOrderApiOrderPropsForSdk interface?
 *
 */
export default function SimplifyStatus(
    status:
        | 'cancelled'
        | 'expired'
        | 'paid'
        | 'paid decide'
        | 'pending'
        | 'refunded',
): 'paid' | 'pending' | 'cancelled' | null {
    switch (status) {
        case 'paid':
            return 'paid';

        case 'cancelled':
            return 'cancelled';

        case 'pending':
            return 'pending';

        case 'expired':
            return 'cancelled';

        case 'refunded':
        default:
            return null;
    }
}
