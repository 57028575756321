<template>
    <div class="confirm-metadata-modal">
        <div class="ot-card">
            <h2>{{ $t('order.components.confirm_metadata.title') }}</h2>
            <div class="confirm-metadata-modal__header">
                <span class="ot-text-tiny">{{
                    $t('order.components.confirm_metadata.ticket')
                }}</span>
                <h4>
                    {{ ticket.ticket.name }}
                    <span class="confirm-metadata-modal__count">{{
                        countTitle
                    }}</span>
                </h4>
            </div>
            <OrderTicketProducts
                v-if="products.length"
                :products="products"
            />
            <OrderItemMetadata :metadata="ticket.metadata" />
            <div class="confirm-metadata-modal__footer">
                <button
                    class="ot-button is-light confirm-metadata-modal__back"
                    @click="$emit('close')"
                >
                    {{ $t('order.components.confirm_metadata.back') }}
                </button>
                <button
                    class="ot-button confirm-metadata-modal__save"
                    @click="save"
                >
                    <i class="oti oti-check ot-button-icon--left" />
                    {{ saveText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { IOrderProduct, IOrderTicket } from '@openticket/lib-order';
import type { TranslateResult } from '@openticket/vue-localization';
import OrderItemMetadata from './OrderItem/OrderItemMetadata.vue';
import OrderTicketProducts from './OrderTicket/OrderTicketProducts.vue';

@Component({
    components: {
        OrderTicketProducts,
        OrderItemMetadata,
    },
})
export default class ConfirmMetadataModal extends Vue {

    @Prop() ticket!: IOrderTicket;
    @Prop() countTitle!: string;
    @Prop() products!: IOrderProduct[];

    save(): void {
        this.$emit('save');
        this.$emit('close');
    }

    get saveText(): TranslateResult {
        if (this.isLastIncomplete) {
            return this.$t('order.components.confirm_metadata.finish');
        }
        return this.$t('order.components.confirm_metadata.next');
    }

    get isLastIncomplete(): boolean {
        let c = 0;
        for (const ticket of Object.values(this.$order.data.ticket_map)) {
            if (!ticket.is_complete && !ticket.invalidated_since) {
                c++;
            }
        }
        return c === 1;
    }

    private get productsWithMetadata(): IOrderProduct[] {
        return this.ticket.products.filter(
            (product: IOrderProduct) => product.metadata && product.metadata.length,
        );
    }

}
</script>

<style lang="scss" scoped>
.confirm-metadata-modal {
    display: flex;
    align-items: center;
    height: 100vh;

    .ot-card {
        width: calc(100% - 1rem);
        max-width: calc(var(--ot-shop-layout-width) - 1rem);
        margin: 0 auto;
        padding-top: 1.25rem;
    }

    h2 {
        font-size: 1.5rem;
        text-align: center;
        padding: 1.25rem;
        width: auto;
    }

    &__footer {
        display: flex;
        padding: 1.25rem;
        justify-content: space-between;
    }

    &__back.is-light.ot-button {
        color: var(--ot-shop-color-brand);
        background-color: var(--ot-shop-color-white);
        font-size: 0.875rem;
    }

    &__save {
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__header {
        padding: 1.25rem;
    }

    &__count {
        color: var(--ot-shop-color-black);
        opacity: 50%;
    }

    .order-item-metadata {
        padding-top: 1.25rem;
    }
}
</style>

<style>
.modal-background {
    background-color: rgba(10, 10, 10, 0.3);
}
</style>
