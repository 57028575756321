<template>
    <div class="ot-card print-order">
        <a
            role="button"
            tabindex="0"
            @click="print"
            @keydown.enter="print"
            @keydown.space="print"
        ><button
            class="ot-button is-large"
            :class="{ 'is-loading': loading }"
        >
            <i class="ot-button-icon--left oti oti-export" />
            {{ $t('order.components.print_order.button') }}
        </button></a>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';
import urlJoin from 'url-join';

@Component
export default class PrintOrder extends Vue {

    loading = false;

    async print(): Promise<void> {
        const adyenWebhookRoute = urlJoin(
            import.meta.env.VITE_API_URL,
            'payment/webhook/adyen',
        );

        if (this.loading) {
            return;
        }

        this.loading = true;

        try {
            const data = {
                printOrder: 'true',
                notificationItems: [
                    {
                        NotificationRequestItem: {
                            merchantReference: this.$order.data.guid,
                        },
                    },
                ],
            };

            const response = await axios.post<string>(adyenWebhookRoute, data);
            this.$notifications.success(response.data);
        } catch (e) {
            if (e && typeof e === 'object' && 'message' in e && typeof e.message === 'string') {
                this.$notifications.warning(e.message);
            }
        } finally {
            this.loading = false;
        }
    }

}
</script>

<style lang="scss" scoped>
.print-order {
    text-align: center;
    margin-bottom: 1rem;
}
</style>
