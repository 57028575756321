<template>
    <div>
        <div
            v-for="event in $order.data.events"
            :key="event.guid"
            :class="`ot-elem-event-${event.guid}`"
        >
            <EventCard
                :event="event"
                :opened-card="openedCard"
                @toggle="toggle"
                @next="$emit('next')"
            />

            <RefundRequestCard
                v-if="
                    event.status_until ||
                        (event.refund_setting &&
                            event.refund_setting.status_until)
                "
                :event="event"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EventCard from './EventCard.vue';
import RefundRequestCard from './RefundRequestCard/RefundRequestCard.vue';

@Component({
    components: {
        EventCard,
        RefundRequestCard,
    },
})
export default class Events extends Vue {

    @Prop({ default: null })
        openedCard!: string | null;

    toggle(guid: string): void {
        this.$emit('toggle', guid);
    }

}
</script>
