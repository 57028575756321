<template>
    <div
        class="splash"
        :class="{ visible }"
    >
        <Spinner class="splash__logo" />
        <h5 v-if="label">
            {{ $t(label) }}
        </h5>
        <div class="splash__content">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SplashScreen extends Vue {

    @Prop() visible!: boolean;
    @Prop() label!: string | null;

}
</script>

<style lang="scss" scoped>
.splash {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ot-shop-color-background);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    z-index: 99;

    &__logo {
        height: 3rem;
    }

    h5 {
        margin-top: 1rem;
        flex: 0;
    }

    &.visible {
        opacity: 1;
        pointer-events: auto;
    }
}
</style>
