<template>
    <div class="cookie-wall-section">
        <h2 class="cookie-wall-section__title">
            {{ title }}
        </h2>

        <p
            v-if="description"
            class="cookie-wall-section__description"
        >
            {{ description }}
        </p>

        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CookieWallSection extends Vue {

    @Prop({ required: true, type: String })
        title!: string;

    @Prop({ default: '', type: String })
        description!: string;

}
</script>

<style lang="scss" scoped>
.cookie-wall-section {
    display: flex;
    flex-direction: column;
    gap: var(--ot-spacing-sm);

    & > * {
        margin: 0;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: var(--ot-spacing-sm);
    }
}
</style>
