<template>
    <div
        class="refund-request-checkbox ot-checkbox"
        :disabled="disabled"
        :class="{ 'ot-highlight': selected, disabled }"
    >
        <!--suppress HtmlFormInputWithoutLabel -->
        <input
            :id="`refund-request-checkbox_${guid}`"
            type="checkbox"
            :checked="selected"
            :disabled="disabled"
            @click="onInput($event)"
        >
        <label
            class="ot-checkbox-label refund-request-checkbox__label"
            :for="`refund-request-checkbox_${guid}`"
        >
            <slot />
        </label>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class RefundRequestCheckbox extends Vue {

    @Prop({ required: true, type: String })
        guid!: string;

    @Prop({ required: true, type: Boolean, default: false })
        selected!: boolean;

    @Prop({ type: Boolean, default: false })
        disabled!: boolean;

    onInput(event: Event): void {
        if (event.target && event.target instanceof HTMLInputElement) {
            const { target } = event;
            this.$emit('input', target.checked);
        }
    }

}
</script>

<style lang="scss" scoped>
.refund-request-checkbox {
    box-sizing: border-box;

    border: 1.5px solid var(--ot-shop-color-box-outline-dropdown);
    box-shadow: 0 2px 4px -2px var(--ot-shop-color-box-dropdown);
    border-radius: 0.375rem;

    &:not(:last-child) {
        margin: 0.5rem 0;
    }

    &.ot-highlight.disabled {
        box-shadow: 0 0 0 2px var(--ot-input-color-disabled-invert),
            0 0 0 4px var(--ot-color-core-brand-faded) !important;
    }
}
</style>
