<template>
    <div class="cookie-wall-toggles">
        <div
            v-for="(spec, category) in categories"
            :key="category"
            class="cookie-wall-toggles__category"
            :class="`cookie-wall-toggles__category__key-${category}`"
        >
            <label
                :for="`cookie-wall-toggles__category__key-${category}`"
                class="cookie-wall-toggles__category__title"
            >
                {{ spec.title }}

                <span class="cookie-wall-toggles__category__title__description">
                    {{ spec.description }}
                </span>
            </label>

            <div class="cookie-wall-toggles__category__right">
                <OtInput
                    v-if="!spec.readonly"
                    :id="`cookie-wall-toggles__category__input-${category}`"
                    v-model="spec.value"
                    type="toggle"
                    class="cookie-wall-toggles__category__right__input"
                    :disabled="spec.readonly"
                />

                <div
                    v-else
                    class="cookie-wall-toggles__category__right__essential"
                >
                    {{ essentialLabel }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { CookieCategories, CookieKeys } from '../../composables/cookies';

@Component
export default class CookieWallSection extends Vue {

    @Prop({ required: true, type: Object })
        categories!: CookieCategories<CookieKeys>;

    @Prop({ required: true, type: String })
        essentialLabel!: string;

}
</script>

<style lang="scss" scoped>
.cookie-wall-toggles {
    display: flex;
    flex-direction: column;
    text-align: start;

    &__category {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: var(--ot-spacing-sm);

        &:not(:first-child) {
            padding-top: var(--ot-spacing-sm);
        }

        &:not(:last-child) {
            padding-bottom: var(--ot-spacing-sm);
            border-bottom: 1px solid var(--ot-shop-color-box-accent);
        }

        & > label.cookie-wall-toggles__category__title {
            font-size: calc(var(--ot-spacing-default) * 0.875);
            line-height: 1.125;
            font-weight: 500;
            color: var(--ot-card-color);
        }

        &__title__description {
            display: block;
            color: inherit;
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0.25rem;
            opacity: 0.6;
        }

        &__right {
            margin: 0;

            &__essential {
                padding: var(--ot-spacing-2xs) var(--ot-spacing-xs);
                background: var(--ot-color-core-brand);
                color: var(--ot-color-core-brand-invert);
                font-weight: 500;
                font-size: var(--ot-spacing-sm);
                line-height: var(--ot-spacing-sm);
                border-radius: var(--ot-spacing-lg);
            }
        }
    }

    &::v-deep {
        .ot-toggle-input {
            &:focus,
            &:focus-within,
            &:focus-visible {
                .ot-toggle-input__input:not(:checked)
                    ~ .ot-toggle-input__label {
                    background-color: var(--ot-color-core-accent-primary);
                }
            }
        }
    }
}
</style>
