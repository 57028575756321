<template>
    <div class="order-ticket-products">
        <span class="ot-text-tiny order-ticket-products__title">{{
            $t('order.components.order_ticket_products.title')
        }}</span>
        <!-- Todo: replace this label with a more sensible element -->
        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
        <label
            v-for="product in products"
            :key="product.guid"
            class="ot-text-small-strong order-ticket-products__item"
            :class="{
                disabled: !!product.invalidated_since,
            }"
        >
            <i
                v-if="!product.invalidated_since"
                class="oti oti-check is-small"
            />
            <i
                v-else
                class="oti oti-close is-small"
            />
            {{ product.product.name }}
        </label>
    </div>
</template>

<script lang="ts">
import type { IOrderProduct } from '@openticket/lib-order';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class TicketProducts extends Vue {

    @Prop() products!: IOrderProduct[];

}
</script>

<style lang="scss" scoped>
.order-ticket-products {
    background-color: var(--ot-shop-color-box-accent);
    border-radius: var(--ot-card-border-radius);
    padding: 1rem 1.25rem;
    margin: 0 0.75rem;
    margin-bottom: 0.75rem;

    &__title {
        opacity: 0.5;
    }

    &__item {
        display: block;
        margin-top: 0.5rem;

        i {
            vertical-align: middle;
            margin-right: 0.5rem;
        }

        &.disabled {
            text-decoration: line-through;
            opacity: 0.5;
        }
    }
}
</style>
