<template>
    <div class="order-view__content__status">
        <BrandedHeader
            :title="title"
            :hide-logo="!showLogo"
        />

        <OrderDownloadButton
            v-if="!$settings || !$settings.static.order.disableDownloadButton"
        />

        <div class="order-view__status">
            <OrderMessages @open="scrollAndOpenIncompleteTicketCard()" />
        </div>

        <WalletsMessage v-if="showWalletIntegrations" />

        <Events
            :opened-card="openedCard"
            @toggle="toggleCard"
            @next="openIncompleteTicketCard()"
        />

        <Products />

        <OrderDetails />

        <PromoBlocks />

        <CoverGenius v-if="showCoverGenius" />

        <RefundProtect v-if="showRefundProtect" />

        <GreenProduct />

        <OrganiserInfo
            v-for="event in $order.data.events"
            :key="`organiser-info-${event.guid}`"
            :event="event"
        />

        <Resell v-if="showResell" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import type { TranslateResult } from '@openticket/vue-localization';
import SimplifyStatus from '../../../utils/simplify_status';

import Events from '../components/Events.vue';
import GreenProduct from '../components/upgrades/GreenProduct.vue';
import OrderDownloadButton from '../components/OrderDownloadButton.vue';
import OrderMessages from '../components/OrderMessages.vue';
import OrganiserInfo from '../components/OrganiserInfo.vue';
import OrderDetails from '../components/OrderDetails.vue';
import Products from '../components/Products.vue';
import RefundProtect from '../components/upgrades/RefundProtect.vue';
import Resell from '../components/Resell.vue';
import SealedTickets from '../components/SealedTickets.vue';
import PromoBlocks from '../components/PromoBlocks.vue';
import { scrollTo, scrollToOffset } from '../../../utils';
import WalletsMessage from '../components/WalletsMessage.vue';
import CoverGenius from '../components/upgrades/CoverGenius.vue';

@Component({
    components: {
        CoverGenius,
        Events,
        GreenProduct,
        OrderDetails,
        OrderDownloadButton,
        OrderMessages,
        OrganiserInfo,
        Products,
        PromoBlocks,
        RefundProtect,
        Resell,
        SealedTickets,
        WalletsMessage,
    },
})
export default class OrderStatusView extends Vue {

    openedCard: string | null = null;

    created(): void {
        this.openIncompleteTicketCard();
    }

    get simplifiedStatus(): 'paid' | 'pending' | 'cancelled' | null {
        return SimplifyStatus(this.$order.data.status);
    }

    /**
     *  Used to get the title for the branded header
     *  component, based on the status of the order.
     */
    get title(): TranslateResult | null {
        switch (this.simplifiedStatus) {
            case 'paid':
                return this.$t('order.components.order.status.paid');

            case 'pending':
                return this.$t('order.components.order.status.pending');

            case 'cancelled':
                return this.$t('order.components.order.status.cancelled');

            default:
                return null;
        }
    }

    get showLogo(): boolean {
        return (
            !this.$settings
            || !this.$settings.static
            || !this.$settings.static.order
            || !this.$settings.static.order.header
            || this.$settings.static.order.header.showLogo
            || this.$settings.static.order.header.showLogo === null
        );
    }

    get showWalletIntegrations(): boolean {
        if (this.$order.data.status !== 'paid' || !this.$settings) {
            return false;
        }

        return (
            this.$settings.static.order.enableAppic
            || this.$settings.static.order.enablePartyPay
            || !!this.$settings.static.order.components.eventCard.closeUrl
        );
    }

    get hasValidTicket(): boolean {
        return Object.values(this.$order.data.ticket_map).some(
            (ticket) => !ticket.invalidated_reason && !ticket.invalidated_since,
        );
    }

    get showCoverGenius(): boolean {
        if (!this.$settings || this.$order.data.status !== 'paid') {
            return false;
        }

        return this.hasValidTicket;
    }

    get showRefundProtect(): boolean {
        if (!this.$settings || this.$order.data.status !== 'paid') {
            return false;
        }

        return this.hasValidTicket;
    }

    get showResell(): boolean {
        if (!this.$settings || this.$order.data.status !== 'paid') {
            return false;
        }

        return (
            !!this.$settings.static.order.enableResell && this.hasValidTicket
        );
    }

    scrollAndOpenIncompleteTicketCard(): void {
        this.openIncompleteTicketCard();
        if (this.openedCard != null) {
            scrollTo({
                id: this.openedCard,
                position: 'center',
            });
        }
    }

    openIncompleteTicketCard(): void {
        // Open first incomplete ticket, and closes if we can not find one
        for (const ticket of Object.values(this.$order.data.ticket_map)) {
            if (!ticket.is_complete && !ticket.invalidated_since) {
                this.openedCard = ticket.guid;
                return;
            }
        }
        this.openedCard = null;
        scrollToOffset(0);
    }

    toggleCard(guid: string): void {
        if (this.openedCard !== guid) {
            this.openedCard = guid;
        } else {
            this.openedCard = null;
        }
    }

}
</script>
