<template>
    <CardSection
        class="refund-request-terms"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <ot-input-checkbox
            v-model="acceptedTerms"
            class="refund-request-terms__accepted"
            :class="{ disabled }"
            :label="$t('order.components.refund_request_card.terms')"
            :disabled="disabled"
        />
    </CardSection>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import CardSection from '../CardSection.vue';
import RefundRequestCardSection from './RefundRequestCardSection.vue';

@Component({ components: { CardSection } })
export default class RefundRequestTerms extends RefundRequestCardSection {

    @PropSync('accepted', { required: true, type: Boolean, default: false })
        acceptedTerms!: boolean;

}
</script>

<style lang="scss" scoped>
.refund-request-terms {
    &__accepted.ot-checkbox::v-deep {
        & > input[type='checkbox'] + label.ot-checkbox-label {
            text-align: initial;

            &::before,
            &::after {
                top: 50%;
                margin-top: -0.625rem;
            }
        }

        &[disabled],
        &.disabled {
            pointer-events: none;
        }

        &[disabled] > input[type='checkbox'],
        &.disabled > input[type='checkbox'],
        & > input[type='checkbox'][disabled],
        & > input[type='checkbox'].disabled {
            & + label.ot-checkbox-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
