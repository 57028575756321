<template>
    <div
        v-if="isBeforeRefundableBefore"
        class="ot-card refund-request-card has-no-padding"
    >
        <RefundRequestCardHeader
            :event="event"
            :expired="eventNoLongerRefundable"
            separator
        />

        <div v-if="refundSetting && tickets.length && eventRefundable">
            <RefundRequestSelect
                ref="select"
                class="refund-request-card__select"
                :refund-settings="refundSetting"
                :selected-tickets.sync="selectedTickets"
                :tickets="tickets"
                :highlight-error="!!errors['select']"
                :disabled="busy"
            />

            <RefundRequestDonate
                v-if="refundSetting.refund_donate_enabled"
                ref="donate"
                class="refund-request-card__donate"
                :refund-setting="refundSetting"
                :donate-option.sync="donateOption"
                :highlight-error="!!errors['donate']"
                :disabled="busy"
                accent
            />

            <RefundRequestPayout
                ref="payout"
                class="refund-request-card__payout"
                :refund-setting="refundSetting"
                :payout-type.sync="payoutType"
                :payout-amount="payoutAmount"
                :highlight-error="!!errors['payout']"
                :disabled="busy"
                separator
            />

            <RefundRequestTerms
                ref="terms"
                class="refund-request-card__terms"
                :accepted.sync="termsAccepted"
                :highlight-error="!!errors['terms']"
                :disabled="busy"
            />

            <CardSection
                class="refund-request-card__submit"
                separator
            >
                <button
                    class="ot-button is-large is-fullwidth"
                    :disabled="busy"
                    @click="submit"
                >
                    {{
                        $t('order.components.refund_request_card.submit.label')
                    }}
                    <i class="oti oti-carrot-right" />
                </button>
            </CardSection>
        </div>

        <div v-else-if="refundSetting && eventRefundable">
            <CardSection>
                {{
                    $t('order.components.refund_request_card.nothing_to_refund')
                }}
            </CardSection>
        </div>

        <div v-else-if="refundSetting && !eventNoLongerRefundable">
            <CardSection>
                {{
                    $t('order.components.refund_request_card.event_not_refundable')
                }}
            </CardSection>
        </div>

        <div v-else-if="!eventNoLongerRefundable">
            <CardSection>
                {{
                    $t('order.components.refund_request_card.no_payout_profile')
                }}
            </CardSection>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    type IOrderEvent,
    type IOrderTicket,
    type IOrderRefundSetting,
    isRefundableTicket,
    isRefundableEvent,
    isNoLongerRefundableEvent,
} from '@openticket/lib-order';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { isOtError } from '@openticket/lib-log';
import CardHeader from '../CardHeader.vue';
import CardSection from '../CardSection.vue';
import RefundRequestCardHeader from './RefundRequestCardHeader.vue';
import RefundRequestSelect from './RefundRequestSelect.vue';
import RefundRequestDonate from './RefundRequestDonate.vue';
import RefundRequestPayout from './RefundRequestPayout.vue';
import RefundRequestTerms from './RefundRequestTerms.vue';
import type { ReturnItem } from '../../../../utils/sdk/orderbuilder';
import { scrollToOffset } from '../../../../utils';
import type { PayoutType, RefundItemCollection } from './types';

interface RefundSectionRefs {
    all: Element;
    select: Element;
    donate: Element;
    payout: Element;
    terms: Element;
}

@Component({
    components: {
        RefundRequestTerms,
        RefundRequestPayout,
        RefundRequestDonate,
        RefundRequestSelect,
        RefundRequestCardHeader,
        CardSection,
        CardHeader,
    },
})
export default class RefundRequestCard extends Vue {

    donateOption: number | null = null;
    payoutAmountBeforeDonate = 0;
    payoutAmount = 0;
    payoutType: PayoutType | null = null;
    returnItems: ReturnItem[] = [];
    selectedTickets: RefundItemCollection = {};
    termsAccepted = false;

    busy = false;
    errors: { [section: string]: number } = {};

    @Prop({ required: true })
        event!: IOrderEvent;

    @Watch('donateOption')
    donateOptionChanged(): void {
        this.payoutAmount = this.payoutAmountBeforeDonate
            * (1 - (this.donateOption || 0) / 100);
    }

    @Watch('selectedTickets')
    selectedTicketsChanged(): void {
        if (!this.refundSetting) {
            return;
        }

        this.returnItems = this.$order.getReturnItems(
            this.event,
            Object.values(this.selectedTickets),
            this.refundSetting,
        );

        let amount = 0;

        for (const returnItem of this.returnItems) {
            switch (returnItem.type) {
                case 'ticket':
                    if (this.$order.data.ticket_map[returnItem.guid]) {
                        const orderTicket: IOrderTicket = this.$order.data
                            .ticket_map[returnItem.guid];

                        amount += orderTicket.finn_price;

                        if (!this.refundSetting.refund_fees) {
                            amount -= orderTicket.finn_service_fee;
                        }
                    }

                    break;

                case 'payment':
                    if (
                        this.$order.data.payment_map[returnItem.guid]
                        && this.refundSetting.refund_transaction_fees
                    ) {
                        amount += this.$order.data.payment_map[returnItem.guid]
                            .finn_service_fee;
                    }

                    break;

                case 'product':
                    // Ignore products, they should all be taken care of ticket.finn_price!

                    break;
                default:
                    break;
            }
        }

        this.payoutAmountBeforeDonate = amount;

        this.donateOptionChanged();
    }

    @Ref('select') readonly refSelect!: RefundRequestSelect;
    @Ref('donate') readonly refDonate!: RefundRequestDonate;
    @Ref('payout') readonly refPayout!: RefundRequestPayout;
    @Ref('terms') readonly refTerms!: RefundRequestTerms;

    get refundSetting(): null | IOrderRefundSetting {
        return this.event.refund_setting || this.event.payout_profile;
    }

    get tickets(): IOrderTicket[] {
        return this.event.tickets.filter((ticket: IOrderTicket) => isRefundableTicket(this.event, ticket));
    }

    get refs(): RefundSectionRefs {
        return {
            all: this.$el,
            select: this.refSelect.$el,
            donate: this.refDonate.$el,
            payout: this.refPayout.$el,
            terms: this.refTerms.$el,
        };
    }

    get eventNoLongerRefundable(): boolean {
        return isNoLongerRefundableEvent(this.event);
    }

    get eventRefundable(): boolean {
        return isRefundableEvent(this.event);
    }

    get isBeforeRefundableBefore(): boolean {
        // When not set, fallback to default behaviour -> allow refund
        if (
            this.refundSetting === null
            || !this.refundSetting.status_refundable_before
        ) {
            return true;
        }

        return (
            new Date(this.$order.data.created_at)
            < new Date(this.refundSetting.status_refundable_before)
        );
    }

    async submit(): Promise<void> {
        if (this.busy) {
            return Promise.resolve();
        }

        if (!this.refundSetting) {
            return this.showAndThrowError(
                'all',
                'order.components.refund_request_card.validation.payout_profile',
            );
        }

        if (
            !Object.keys(this.selectedTickets).length
            || !this.returnItems.length
        ) {
            // TODO Decent error
            return this.showAndThrowError(
                'select',
                'order.components.refund_request_card.validation.select',
            );
        }

        if (
            this.refundSetting.refund_donate_enabled
            && this.donateOption === null
        ) {
            // TODO Decent error
            return this.showAndThrowError(
                'donate',
                'order.components.refund_request_card.validation.donate',
            );
        }

        if (!this.payoutType) {
            // TODO Decent error
            return this.showAndThrowError(
                'payout',
                'order.components.refund_request_card.validation.payout',
            );
        }

        if (!this.termsAccepted) {
            // TODO Decent error
            return this.showAndThrowError(
                'terms',
                'order.components.refund_request_card.validation.terms',
            );
        }

        // TODO: Log about to request refund + determine which data...

        try {
            this.busy = true;

            let donateOption: number | null = null;

            // The input v-model does not return numbers, only strings.
            if (
                this.donateOption !== null
                && !Number.isNaN(+this.donateOption)
            ) {
                donateOption = +this.donateOption;
            }

            await this.$order.requestRefund(
                this.refundSetting,
                this.returnItems,
                this.payoutType === 'voucher',
                donateOption,
            );
        } catch (e: unknown) {
            this.busy = false;

            if (isOtError(e)) {
                const data = e.getLogContext() || {};
                if ('oldSlug' in data && data.oldSlug) {
                    // Backwards compatibility
                    const { oldSlug } = data;
                    if (this.$te(oldSlug)) {
                        if (data && 'firstError' in data && data.firstError && typeof data.firstError === 'string') {
                            data.firstError = this.$t(data.firstError, data);
                        }

                        this.$notifications.danger(this.$t(oldSlug, data));
                        return Promise.resolve();
                    }
                } else {
                    // New error handling
                    const { slug } = e;
                    if (this.$te(slug)) {
                        if (data && 'firstError' in data && data.firstError && typeof data.firstError === 'string') {
                            data.firstError = this.$t(data.firstError, data);
                        }

                        this.$notifications.danger(this.$t(slug, data));
                        return Promise.resolve();
                    }
                }
            }

            if (e && typeof e === 'object' && 'toString' in e && typeof e.toString === 'function') {
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                this.$notifications.danger(e.toString());
            }

            return Promise.resolve();
        }

        // TODO: Log success + new order_id (parent: log message before the request)

        this.$notifications.show({
            message: this.$t(
                'order.components.refund_request_card.submit.success',
            ),
            type: 'is-success',
        });

        window.top!.location.reload();
        return Promise.resolve();
    }

    showAndThrowError(section: keyof RefundSectionRefs, message: string): Promise<void> {
        this.$notifications.danger(this.$t(message));

        // When the errors object has the key for the section,
        // the section is highlighted. By setting the value to the timeout,
        // it is easy to remove the highlight both automatically
        // and when a new error was received and the highlight timer should be reset.
        if (this.errors[section]) {
            window.clearTimeout(this.errors[section]);
        }

        this.$set(
            this.errors,
            section,
            window.setTimeout(() => {
                this.$delete(this.errors, section);
            }, 750),
        );

        scrollToOffset(
            Math.max(
                this.refs[section].getBoundingClientRect().top
                    + window.scrollY
                    - 80,
                0,
            ),
        );

        // TODO Decent error ??
        throw new Error(message);
    }

}
</script>

<style lang="scss" scoped>
.refund-request-card {
    margin-bottom: 1rem;
    text-align: center;

    &__terms {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    &__submit {
        font-weight: 500;

        margin: 0 0 1.25rem 0;
        padding-top: 0;
        padding-bottom: 1.25rem;

        & > .ot-button > .oti {
            margin-left: 0.75rem;
        }
    }
}
</style>
